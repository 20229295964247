import React, { Component } from 'react';

const avatarFallbackImage = '/images/DcassoDuel.png';

export default class SketchTopBlank extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return(
      <div className="sketch-top-wrapper">

        <div className="photos-wrapper">
          < div className="photos"
              style={{
              backgroundImage: `url(${  avatarFallbackImage })` ,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'}}/>
        </div>
        <div className="top-user-data">

          <div className="">
            <div  className="top-username">
            Dcasso User
          </div> 
          </div>
          <br />
          <div className="">
            <div  className="top-createdAt" >
            galaxy time
          </div>
        {/* <span className="badge badge-pill badge-danger">Challenger</span> */}

          </div>
        </div>
      </div>
    )
  }

  componentDidMount(){
  }
}