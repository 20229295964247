import React, { Component } from "react";
import DcassoCanvas from "../NewSketch/DcassoCanvas";
import Timer from "../NewSketch/Timer";
import Mode from "../NewSketch/Mode";
import RandomWord from "../NewSketch/RandomWord.js";
import DuelPromptMode from "../../models/DuelPromptMode";
import { withRouter } from "react-router";
import RematchModal from "./RematchModal";
import DuelRematchRequest from "../../models/DuelRematchRequest";
import DuelRematchRespond from "../../models/DuelRematchRespond";
import StartCountdownModal from "./StartCountdownModal";
import ExitWarningModal from "./ExitWarningModal";
import DuelNotification from "../../models/DuelNotification";
import DuelStatus from "../../models/DuelStatus";
import DuelInvitation from "../../models/DuelInvitation";

class DuelSketch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeStart: false,
      timeUp: false,
      randomWord: "",
      mode: "easy",
      requestModalOpen: false,
      exitModalOpen: false,
    };
    const promptCallback = duelInvitation => {
      const { invitation } = this.props;

      if (duelInvitation.attrs._id === invitation.attrs._id) {
        // if ((!this.state.timeStart && !this.state.timeUp)||(this.state.timeUp && !this.state.timeStart)) {
          this.setState({
            randomWord: duelInvitation.attrs.prompt,
          });

  
        // }
      }
    };
    DuelInvitation.addStreamListener(promptCallback.bind(this));

    const rematchCallback = duelRematchRequest => {
      const { userSession } = this.props;

      if (
        duelRematchRequest.attrs.invitationId ===
          this.props.match.params.invitationId &&
        duelRematchRequest.attrs.username !==
          userSession.loadUserData().username
      ) {
        this.openRequestModal();
      }
    };
    DuelRematchRequest.addStreamListener(rematchCallback.bind(this));

    const rematchRespondCallback = duelRematchRespoond => {
      const { userSession, invitation } = this.props;

      if (
        duelRematchRespoond.attrs.invitationId ===
        this.props.match.params.invitationId
      ) {
        if (duelRematchRespoond.attrs.accept) {
          // this.props.openCountdownModal()
          this.setTimeRestart()  
          this.sendUnreadyNoti()
        }
      }
    };
    DuelRematchRespond.addStreamListener(rematchRespondCallback.bind(this));
  }
  componentDidMount() {
    // this.createDuelPrompMode()
    const { invitation , startSketch } = this.props;
    this.setState({
      mode : invitation.attrs.mode,
      prompt : invitation.attrs.prompt
    })
    if (startSketch) {
      this.startSketch();
    }
  }

  componentDidUpdate(prevProps) {
    const { invitation, startSketch } = this.props;
    if (prevProps.startSketch !== startSketch && startSketch) {
      this.startSketch();
    }
  }

  setWord(_randomWord) {
    this.setState({ randomWord: _randomWord },
      () =>{
        if (this.props.meIsChallenger){
          // this.createDuelPrompMode()
          this.setPrompt()

        }

      } 
    );
  }

  async setPrompt(){
    this.props.invitation.update({
      prompt : this.state.randomWord
    })
    await this.props.invitation.save()
  }

  /**
   * start timer, mode to draw sketch
   * @param {*} e
   */
  startSketch(e) {
    //maybe have start new sketch vs tryagain, both set timestart true but have another retry function
    this.setState({
      timeStart: true,
      timeUp: false
    });
  }

  setTimeUp() {
    this.sendDoneNoti();
    this.setState({
      timeUp: true,
      timeStart: false
    });
  }

  setTimeRestart() {
    this.setState({
      timeStart: false,
      timeUp: false
    });
  }
  setMode(_mode) {
    this.setState(
      {
        mode: _mode
      });
  }

  /**
   * send a reques to redo this sketch
   */
  async requestRematch() {
    const { userSession } = this.props;
    try {
      const request = new DuelRematchRequest({
        invitationId: this.props.match.params.invitationId,
        username: userSession.loadUserData().username
      });
      await request.save();
    } catch (e) {
      alert("Unable to request for a rematch atm. Please try again later");
      console.log(e);
    }
  }

  /**
   * discard this sketch
   */
  discard() {
    this.openExitModal();
  }

  async sendReadyNoti(){
    const { userSession } = this.props;
    try{
    const noti = new DuelStatus({
      invitationId : this.props.match.params.invitationId,
      username : userSession.loadUserData().username,
      status: "READY"

    })
    await noti.save();
    } catch (e) {
      console.log(e);
    }
  }


  async sendUnreadyNoti(){
    const { userSession } = this.props;
    try{
    const noti = new DuelStatus({
      invitationId : this.props.match.params.invitationId,
      username : userSession.loadUserData().username,
      status: "NOT READY"

    })
    await noti.save();
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * when this player clicks 'finish' (which means they finish earli)
   * notifies the other player
   */
  async sendDoneNoti() {
    const { userSession } = this.props;

    try {
      const noti = new DuelStatus({
        invitationId: this.props.match.params.invitationId,
        username: userSession.loadUserData().username,
        status: "DONE"
      });
      await noti.save();
    } catch (e) {
      console.log(e);
    }
  }
  /*********** MODAL STUFF ************/
  openRequestModal() {
    this.setState({
      requestModalOpen: true
    });
  }
  closeRequestModal() {
    this.setState({
      requestModalOpen: false
    });
  }
  openExitModal() {
    this.setState({
      exitModalOpen: true
    });
  }
  closeExitModal() {
    this.setState({
      exitModalOpen: false
    });
  }
  render() {
    const { userSession, meIsChallenger } = this.props;
    // console.log(meIsChallenger)
    return (
      <div className="container">
        <ExitWarningModal
          userSession={userSession}
          modalIsOpen={this.state.exitModalOpen}
          closeModal={this.closeExitModal.bind(this)}
        />
        <RematchModal
          userSession={userSession}
          modalIsOpen={this.state.requestModalOpen}
          closeModal={this.closeRequestModal.bind(this)}
        />
        <Mode
          timeStart={this.state.timeStart}
          // setMode={this.setMode.bind(this)}
          mode={this.state.mode}
          duel={true}
        />
        <RandomWord
          timeStart={this.state.timeStart}
          timeUp={this.state.timeUp}
          mode={this.state.mode}
          randomWord={this.state.randomWord}
          setWord={this.setWord.bind(this)}
          duel={true}
          meIsChallenger={meIsChallenger}
        />
        <Timer
          initialTime={this.state.initialTime}
          timeRemaining={this.state.timeRemaining}
          timeStart={this.state.timeStart}
          timeUp={this.state.timeUp}
          setTimeUp={this.setTimeUp.bind(this)}
          mode={this.state.mode}
        />
        <div>
          <DcassoCanvas
            timeStart={this.state.timeStart}
            timeUp={this.state.timeUp}
            userSession={userSession}
            mode={this.state.mode}
            word={this.state.randomWord}
            setTimeRestart={this.setTimeRestart.bind(this)}
            bothDone={this.props.bothDone}
            duel={true}
            invitationId={this.props.invitation.attrs._id}
          />
        </div>

        {!this.state.timeUp && !this.state.timeStart ? (
          ""
        ) : !this.state.timeUp && this.state.timeStart ? (
          <button
            className="btn btn-danger button-radius button-start"
            onClick={e => this.setTimeUp(e)}
          >
            Done
          </button>
        ) : (
          <React.Fragment>
            <button
              className="btn btn-outline-secondary button-start"
              onClick={this.requestRematch.bind(this)}
            >
              Trash & Retry
            </button>
            <button
              className="btn btn-outline-danger button-start"
              onClick={this.discard.bind(this)}
            >
              Trash & Quit
            </button>
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default withRouter(DuelSketch);
