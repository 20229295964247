import { Model } from 'radiks';

export default class DuelRematchRequest extends Model {
    static className = 'DuelRematchRequest';
    static schema = {
        invitationId: { type: String, required : true, decrypted: true },
        username : { type: String, required : true, decrypted: true },
        rematchRequest: { type: Boolean, required : true, decrypted: true }, 
    };
    static defaults = {
      rematchRequest : true
    };
}

