import React, { Component } from "react";

const buttonStyles = " reaction-button btn-reaction btn-reaction-clicked";
const leftButton = " reaction-button-left-side";
const centerButton = " reaction-button-center";
const rightButton = " reaction-button-right-side";
const selectedClass = " btn-reaction-selected";

export default class DuelReactionBar extends Component {

  render() {
    // const { sketch } = this.props;
    // const { loveCount, laughCount, poopCount } = this.props;
    return (
      <div className="reaction-bar-wrapper">
        <div className="reaction-button-wrapper">
          {/* <button type="button" className="btn btn-outline-dark btn-sm btn-reaction btn-reaction-clicked" data-toggle="tooltip" title="Love" */}
          <button
            className={
              this.props.selected
                ? buttonStyles + selectedClass + leftButton + rightButton
                : buttonStyles + leftButton + rightButton
            }
            data-toggle="tooltip"
            title="Love"
            onClick={() => this.props.onReact()}
          >
            <i className="far fa-grin-hearts fa-2x"></i>
            {this.props.reactionCount}
          </button>
        </div>
      </div>
    );
  }
}
