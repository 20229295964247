import React, { Component } from 'react';
var data = require('../../json/nounlist.json'); 

export default class RandomWord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      randomWord : ''
    }
  }

  /**
   * Helper for get word
   */
  getRandomWord(mode){
    
    const { userSession, meIsChallenger } = this.props;
    let _randomWord = data[Math.floor(Math.random() * data.length)];
    this.setState({
      randomWord : _randomWord
    })
    this.props.setWord(_randomWord);
    // console.log("Get random word",_randomWord)
  }

  getWord(mode){
    if (mode === 'easy') {
      data = require('../../json/nouneasy.json')
    } else if (mode === 'medium'){
      data = require('../../json/nounmedium.json')
    } else if (mode === 'hard'){
      data = require('../../json/nounhard.json')
    }
    this.getRandomWord(mode);
  }

  
  render(){
    return(
      <div>
        {/* {this.props.randomWord} {this.state.randomWord} */}
      {this.props.timeStart? 
      <div className="timer-prompt">Your prompt is  : {this.props.duel? this.props.randomWord : this.state.randomWord} </div>
      :
      ''}
      </div>
    )
  }
  
  componentDidMount(){
    this.getWord(this.props.mode);
  }

  componentDidUpdate(prevProps){
    const { meIsChallenger } = this.props;
    if (!this.props.duel) { //bad code design but easier for brain to understand
      if (prevProps.mode !== this.props.mode  ||
        (!prevProps.timeStart && this.props.timeStart && !prevProps.timeUp && !this.props.timeUp)
        ) {
        this.getWord(this.props.mode);
      }
  
    } else {
      // if duel mode
      if ((prevProps.mode !== this.props.mode  ||
        (!prevProps.timeStart && !this.props.timeStart && prevProps.timeUp && !this.props.timeUp)
        )) {
            this.getWord(this.props.mode);
      }
    }
  }
}