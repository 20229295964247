import React, { Component } from 'react';
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import DuelInvitationRespond from '../../models/DuelInvitationRespond';
import { withRouter } from "react-router";
import DuelMatch from "../../models/DuelMatch";

Modal.setAppElement('body')
const customStyles = {
  content: {
    width: '700px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '30px'
  }
};
class InviteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  acceptInvitation() {
    const { invitation, userSession } = this.props

    DuelInvitationRespond.acceptInvitation(userSession , invitation)
    .finally(() => {
      DuelMatch.createMatch(userSession, invitation)
    })
  }

  denyInvitation() {
    const { invitation, userSession } = this.props

    DuelInvitationRespond.denyInvitation(userSession , invitation).finally(() => {
      this.props.closeModal()
    })
  }

  
  render() {
    const { invitation, userSession } = this.props
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        // onRequestClose={this.props.closeModal}	      
        style={customStyles}
        contentLabel="Log In"
      >
        <div className="container">
          <div className="row" >
            <div className="col-12" >
              <img src="/images/DcassoDuel.png" width="50" /><br />
              <h3 className="modal-title">🎉 NEW CHALLENGE REQUEST🎉</h3>
              <div style={{padding : '10px', borderRadius : '10px', border : '2px solid #ddd'}}>
                <h5 className="">From : <code style={{color : "#ff6781 "}}>{invitation.attrs.sender}</code></h5>
                <h5 className="">To : <code style={{color : "#ff6781 "}}>{userSession.loadUserData().username}</code></h5>
              </div>
              <h5>Mode : <code style={{color : "#ff6781 "}}>{invitation.attrs.mode}</code></h5>
     
          </div>
          </div>
          <div className="row" >
            <div className="col" >

              <button type="button" className="btn btn-primary" onClick={this.acceptInvitation.bind(this)}>
                Accept Invitation
          </button>
            </div>
            <div className="col" >

              <button type="button" className="btn btn-secondary" onClick={this.denyInvitation.bind(this)}>
                Deny Invitation
          </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
export default withRouter(InviteModal)