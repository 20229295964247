import { Model } from 'radiks';

export default class DuelOnline extends Model {
    static className = 'DuelOnline';
    static schema = {
        username : { type: String, required : true, decrypted: true },
        online : { type: Boolean, required : true, decrypted: true },
        defaultOnline : { type: Boolean, required : true}
    };
    static defaults = {
      online : true,
      defaultOnline : true
    };
    setOnline(){
    }

    setOffline(){

    }
  }