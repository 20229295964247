import React, { Component } from "react";

import StartCountdownModal from "./StartCountdownModal";
import { withRouter } from "react-router";
import DuelStatus from "../../models/DuelStatus";

class DuelMatchTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myStatus: "NOT READY",
      otherPlayerStatus: "NOT READY",
      modalIsOpen: false
    };
    const readyCallback = dualReady => {
      const { invitation, me, otherPlayer } = this.props;

      //make sure to check inviID = this invi
      if (
        dualReady.attrs.username === otherPlayer.username &&
        dualReady.attrs.username !== me.username
      ) {
        this.setState({
          otherPlayerStatus: dualReady.attrs.status
        },()=>this.handleStatusChange());
      } else if (
        dualReady.attrs.username === me.username &&
        dualReady.attrs.username !== otherPlayer.username
      ) {
        this.setState({
          myStatus: dualReady.attrs.status
        }, () => {
          this.handleStatusChange()
        });
      }
      
    };
    DuelStatus.addStreamListener(readyCallback.bind(this));
  }
  handleStatusChange(){
    this.startIfBothReady()
    this.publishIfBothDone()
  }
  startIfBothReady(){
    const { myStatus, otherPlayerStatus } = this.state;
    if (myStatus === "READY" && otherPlayerStatus === "READY") {
      this.props.setBothDone(false);
      this.openCountdownModal();
    }
  }
  publishIfBothDone(){
    const { myStatus, otherPlayerStatus } = this.state;
    if (myStatus === "DONE" && otherPlayerStatus === "DONE") {
      this.props.setBothDone(true);
    }
  }
  componentDidMount() {
    const { me, otherPlayer } = this.props;
    const { myStatus, otherPlayerStatus } = this.state;

    if (me.username !== "You") {
      this.getReadyState()
      // .finally(()=>this.startIfBothReady());
    }
    
  }
  componentDidUpdate(prevProps) {
    const { me, otherPlayer } = this.props;
    const { myStatus, otherPlayerStatus } = this.state;

    if (prevProps.me !== me && me.username !== "You") {
      this.getReadyState()
      // uncomment for testing purpose
      // this.openCountdownModal() 
    }
  }
  async getUserReady() {
    //new get user ready
    const { me } = this.props;
    let _userReady = await DuelStatus.fetchOwnList({
      invitationId: this.props.match.params.invitationId
    });
    if (_userReady.length === 0) {
      let _ready = new DuelStatus({
        invitationId: this.props.match.params.invitationId,
        username: me.username,
        status : "NOT READY"
      });
      await _ready.save();
      this.setState({ myStatus: _ready.attrs.status });
    } else {
      this.setState({
        myStatus: _userReady[0].attrs.status
      });
    }
  }
  async getOtherUserReady() {
    //new get user ready
    const { otherPlayer } = this.props;
    let _otherUserReady = await DuelStatus.findOne({
      invitationId: this.props.match.params.invitationId,
      username: otherPlayer.username,
    });
    if (_otherUserReady) {
      this.setState({
        otherPlayerStatus: _otherUserReady.attrs.status
      });
    }
  }

  /**
   * get player's ready state at the beginning
   * 
   */
  async getReadyState() {
    const { me, otherPlayer, userSession } = this.props;
    this.getUserReady();
    this.getOtherUserReady();
   }

  /**
   * sends ready notification object for opponent
   */
  async setReady() {
    const { userSession } = this.props;
    let ready = await DuelStatus.findOne({
      invitationId: this.props.match.params.invitationId,
      username: userSession.loadUserData().username,
    });
    ready.update({
      status : "READY"
    });
    await ready.save();
  }

  openCountdownModal() {
    this.setState({
      modalIsOpen: true
    });
  }
  closeCountdownModal() {
    this.setState({
      modalIsOpen: false
    });
  }
  render() {
    const { me, otherPlayer, invitation, meIsChallenger } = this.props;
    const { myStatus, otherPlayerStatus } = this.state;
    return (
      <React.Fragment>
        <StartCountdownModal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeCountdownModal.bind(this)}
          startSketch={this.props.startSketch}
        />
        <div className="row duel-banner">
          <div className=" duel-banner-col">
            <div
              className="photos"
              style={{
                backgroundImage: `url(${me.avatarUrl})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }}
            />
            {/* <b>you</b> */}
            <p>{me.username} </p>
            {meIsChallenger &&
            <span className="badge badge-pill badge-danger challenger-tag">Challenger</span>
            }
            <button className="btn btn-outline-danger duel-ready-button">
              {myStatus}
            </button>
          </div>
          <div className="verses">VS</div>
          <div className="duel-banner-col">
            <div
              className="photos"
              style={{
                backgroundImage: `url(${otherPlayer.avatarUrl})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }}
            />
            {/* <b>your rival</b> */}
            <p>{otherPlayer.username} </p>
            {!meIsChallenger &&
            <span className="badge badge-pill badge-danger challenger-tag">Challenger</span>
            }
            <button className="btn btn-outline-danger duel-ready-button">
              {otherPlayerStatus}
            </button>
          </div>
        </div>
        {myStatus === "NOT READY" && (
          <React.Fragment>
            <div>
            <small style={{color : 'black'}}>Click Ready when you're ready</small>
            </div>
          <button
            className="btn-primary button-radius button-start"
            onClick={e => this.setReady(e)}
          >
            Ready
          </button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(DuelMatchTop);
