import { Model } from 'radiks';

export default class DuelInvitationRespond extends Model {
    static className = 'DuelInvitationRespond';
    static schema = {
      invitationId: { type: String, required : true, decrypted: true },
      username : { type: String, required : true, decrypted: true },
      accept : { type: Boolean, required : true, decrypted: true }
    }
    static defaults = {
      accept : false
    };

    static async acceptInvitation(userSession, invitation) {
      console.log("acceptInvitation")
      try {
        const accept = new DuelInvitationRespond({
          invitationId: invitation.attrs._id,
          username: userSession.loadUserData().username,
          accept: true
        })
        await accept.save()
      } catch (e) {
        console.log(e)
      }
    }

    
  static async denyInvitation(userSession, invitation) {
    try {
      const deny = new DuelInvitationRespond({
        invitationId: invitation.attrs._id,
        username: userSession.loadUserData().username,
        accept: false
      })
      await deny.save()
    } catch (e) {
      console.log(e)
    }
  }

}

