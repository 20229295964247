import React, { Component } from "react";

export default class SketchBlank extends Component {
  render(){
    return(
      <div className="sketch-blank">

      </div>
    )
  }
}