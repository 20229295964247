import { Model } from 'radiks';

export default class DuelRematchRespond extends Model {
    static className = 'DuelRematchRespond';
    static schema = {
        invitationId: { type: String, required : true, decrypted: true },
        username : { type: String, required : true, decrypted: true },
        accept: { type: Boolean, required : true, decrypted: true }, 
    };
    static defaults = {
      accept : false
    };
}

