import { Model } from 'radiks';

export default class DuelPromptMode extends Model {
    static className = 'DuelPromptMode';
    static schema = {
        invitationId: { type: String, required : true, decrypted: true },
        // username : { type: String, required : true, decrypted: true },
        prompt: { type: String, required : true, decrypted: true }, 
        mode : { type: String, required : true, decrypted: true },
    };
    static defaults = {
    };
}

