import React, { Component } from "react";
import CanvasDraw from "react-canvas-draw";
import { SketchPicker } from "react-color";
import { ChromePicker } from "react-color";
import { PhotoshopPicker } from "react-color";
import { User } from "radiks";
import $ from "jquery";
import Sketch from "../../models/Sketch";
import BasicColorPicker from "./BasicColorPicker";
import DuelSketch from "../../models/DuelSketch";
import { withRouter } from "react-router";
import PublishCountdown from "../DuelMatch/PublishCountDown";
import DuelStatus from "../../models/DuelStatus";

class DcassoCanvas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 500,
      height: 500,
      brushRadius: 5,
      brushColor: "black",
      pickerColor: {
        r: 51,
        g: 51,
        b: 51,
        a: 70
      },
      lazyRadius: 0,
      timeRemaining: this.props.timeRemaining,
      isButtonLoading: false
    };
  }
  componentDidUpdate(prevProps){
    if (!prevProps.timeStart && !this.props.timeStart && prevProps.timeUp && !this.props.timeUp) {
      this.saveableCanvas.clear();
    }

  }
  setBrushRadius(event) {
    this.setState({
      brushRadius: parseInt(event.target.value)
    });
  }
  setLazyRadius(event) {
    this.setState({
      lazyRadius: parseInt(event.target.value)
    });
  }
  setBrushColor(color) {
    this.setState({
      pickerColor: color.rgb,
      brushColor: `rgba(${this.state.pickerColor.r}, ${this.state.pickerColor.g}, ${this.state.pickerColor.b}, ${this.state.pickerColor.a})`
    });
  }

  setEraser() {
    this.setState({
      brushColor: "rgba(255,255,255,100)",
      brushRadius: 20
    });
  }

  handleColorChange(event) {
    this.setState({
      brushColor: event.target.value
    });
  }

  async saveSketch() {
    this.setState({ isButtonLoading: true });
    let canvas = document.getElementsByTagName("canvas");
    const { userSession } = this.props;

    try {
      const sketch = new Sketch({
        username: userSession.loadUserData().username,
        binary: canvas[1].toDataURL(),
        prompt: this.props.word,
        mode: this.props.mode
      });
      await sketch.save();
      this.setState({});
      this.props.history.push(`/`);
    } catch (e) {
      this.setState({ isButtonLoading: false });
      alert("We apologize. Unable to create new sketch. Try again later");
    }
  }

  tryAgain() {
    this.saveableCanvas.clear();
    this.props.setTimeRestart();
  }

  async sendPublishNoti(){
    const { userSession } = this.props;

    const publish = new DuelStatus({
      invitationId: this.props.invitationId,
      username: userSession.loadUserData().username,
      status: 'PUBLISHED'
    })
    await publish.save()
  }

  /**
   * publish duel sketch
   */
  async saveDuelSketch() {
    console.log("save duel sketch")
    this.setState({ isButtonLoading: true });
    let canvas = document.getElementsByTagName("canvas");
    const { userSession } = this.props;

    try {
      const sketch = new DuelSketch({
        invitationId: this.props.invitationId,
        username: userSession.loadUserData().username,
        binary: canvas[1].toDataURL(),
        prompt: this.props.word,
        mode: this.props.mode
      });
      await sketch.save();
      this.sendPublishNoti()
      this.props.history.push(`/`);
    } catch (e) {
      this.setState({ isButtonLoading: false });
      console.log(e)
      alert("We apologize. Unable to create new sketch. Try again later");
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.props.timeUp && this.props.bothDone &&
         <PublishCountdown
         saveDuelSketch = {this.saveDuelSketch.bind(this)}/>}
        <div className="row flex-row">
          <div className="col-3 card-border sketch-settings-wrapper">
            <div
              className="settings-card-wrapper"
              style={{ width: "100%", height: "100%", background: "white" }}
            >
              <h4 className="settings-text" style={{ float: "left" }}>
                Settings
              </h4>

              <BasicColorPicker
                onClick={event => this.handleColorChange(event)}
                brushColor={this.state.brushColor}
              />
              {/* <ChromePicker color={this.state.pickerColor}
                onChange={this.setBrushColor.bind(this)} /> */}
              <div>
                <label htmlFor="customRange1">Brush size</label>
                <input
                  type="range"
                  className="range"
                  id="brushRadius"
                  min="2"
                  max="50"
                  value={this.state.brushRadius}
                  onChange={e => this.setBrushRadius(e)}
                />
              </div>
              {/* <div>
                <label htmlFor="customRange1">Lazy brush length</label>
                <input type="range" className="range" id="lazyRadius" min="0" max="200" value={this.state.lazyRadius}
                  onChange={e => this.setLazyRadius(e)} />
              </div> */}
              <div>
                <button
                  className="btn btn-light"
                  onClick={this.setEraser.bind(this)}
                >
                  Eraser <i className="fas fa-eraser"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-9 card-border canvas-wrapper">
            <CanvasDraw
              ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
              disabled={!this.props.timeStart}
              hideGrid
              brushRadius={this.state.brushRadius}
              brushColor={this.state.brushColor}
              lazyRadius={this.state.lazyRadius}
              canvasWidth={this.state.width}
              canvasHeight={this.state.width}
            />
          </div>
        </div>
        <div className="row flex-row">
          {this.props.timeUp && !this.props.timeStart ? (
            <div className="col .card-border">
              {this.props.duel ? (
                <React.Fragment>
                  <button
                    className="btn btn-primary button-start"
                    disabled={this.state.isButtonLoading}
                    onClick={this.saveDuelSketch.bind(this)}
                  >
                    {!this.state.isButtonLoading ? (
                      <div>Publish Sketch</div>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <button
                    className="btn btn-outline-secondary button-start"
                    onClick={this.tryAgain.bind(this)}
                  >
                    Discard and Try New Word
                  </button>
                  <button
                    className="btn btn-primary button-start"
                    disabled={this.state.isButtonLoading}
                    onClick={this.saveSketch.bind(this)}
                  >
                    {!this.state.isButtonLoading ? (
                      <div>Publish Sketch</div>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </button>
                </React.Fragment>
              )}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(DcassoCanvas)