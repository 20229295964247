import React, { Component } from "react";

const buttonStyles = " reaction-button btn-reaction btn-reaction-clicked";
const leftButton = " reaction-button-left-side";
const centerButton = " reaction-button-center";
const rightButton = " reaction-button-right-side";
const selectedClass = " btn-reaction-selected";

export default class DuelReactionBlank extends Component {

  render() {
    return (
      <div className="reaction-bar-wrapper">
        <div className="reaction-button-wrapper">
          <button
            className={
              
                buttonStyles + leftButton + rightButton
            }
            data-toggle="tooltip"
            title="Left"
            // onClick={() => this.props.onReact()}
          >
            <i className="fas fa-trash fa-2x"></i>
            {/* {this.props.reactionCount} */}
            🤪 Sketch unavailable
          </button>
        </div>
      </div>
    );
  }
}
