import React, { Component } from "react";
import Reaction from "../../models/Reaction";
import { User } from "radiks/lib";
import Sketch from "../../models/Sketch";
import DeleteSketchButton from "../SingleSketch/DeleteSketchButton";
import SketchTop from "../SingleSketch/SketchTop";
import DuelReactionBar from "../DuelHome/DuelReactionBar";
import DuelVote from "../../models/DuelVote";
import SketchBlank from "../Blank/SketchBlank";
import DuelItem from "./DuelItem";
import DuelSketch from "../../models/DuelSketch";
import SignInModal from "../Shared/SignInModal";

const selectedClass = "btn-reaction-selected";

export default class DuelListItem extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      signInModalOpen: false,
      modalIsOpen: false,
      reactionCount1: 0,
      reactionCount2: 0,
      isReacted1: false,
      isReacted2: false,
      avatar1: null,
      avatar2: null
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const { userSession } = this.props;
    const { match } = this.props;
    // this.getAvatar();
    this.getSketchReations();
    if (userSession.isUserSignedIn()) {
      this.getSelfReaction();
    }
    // this.getSketchesByInvitationId(match).finally(() => {
    //   // this.getAvatar();
    //   this.getSketchReations()
    //   if (userSession.isUserSignedIn()) {
    //   this.getSelfReaction();
    //   }
    // });
  }

  componentWillMount(){
    this._isMounted = false;
  }
  async getSketchesByInvitationId(match) {
    const matchId = match.attrs.invitationId; //this could change it lame
    let _sketches = await DuelSketch.fetchList(
      { invitationId: matchId },
      { decrypt: false }
    );
    if (_sketches.length > 0) {
      this.setState({
        sketch1: _sketches[0],
        sketch2: _sketches.length == 2 ? _sketches[1] : null //how to handle sketch that does not exist?
      });
    }
  }
  async getAvatar() {
    let _user = await User.findById(this.props.sketch.attrs.username, {
      decrypt: false
    });
    this.setState({ avatar: _user.avatarURL });
  }

  async getSketchReations() {
    const { sketch1, sketch2 } = this.props;

    let _reactions1 = await DuelVote.fetchList(
      { sketchId: sketch1.attrs._id },
      { decrypt: false }
    );
    let _reactionCount1 = _reactions1.length;
    this.setState({
      reactionCount1: _reactionCount1
    });
    if (sketch2) {
      let _reactions2 = await DuelVote.fetchList(
        { sketchId: sketch2.attrs._id },
        { decrypt: false }
      );
      let _reactionCount2 = _reactions2.length;
      this.setState({
        reactionCount2: _reactionCount2
      });
    }
  }

  async getSelfReaction() {
    const { sketch1, sketch2 } = this.props;

    let _reaction = await DuelVote.findOne({
      invitationId: sketch1.attrs.invitationId,
      userId: User.currentUser()._id
    });
    if (_reaction && _reaction.attrs.sketchId === sketch1._id) {
      this.setState({ isReacted1: true, isReacted2: false });
    } else if (_reaction && _reaction.attrs.sketchId === sketch2._id) {
      this.setState({ isReacted1: false, isReacted2: true });
    }
  }
  handleReact1() {
    const {userSession} = this.props;
    if (!userSession.isUserSignedIn()) {
      this.openSignInModal();
    } else {
      let _isReacted1 = this.state.isReacted1;
      this.resetReaction();
      if (!_isReacted1) {
        this.setState(
          {
            isReacted1: true,
            isReacted2: false,
            reactionCount1: this.state.reactionCount1 + 1
          },
          () => {
            this.saveReaction();
          }
        );
      }
    }
  }

  handleReact2() {
    const {userSession} = this.props;

    if (!userSession.isUserSignedIn()) {
      this.openSignInModal();
    } else {
      let _isReacted2 = this.state.isReacted2;
      this.resetReaction();
      if (!_isReacted2) {
        this.setState(
          {
            isReacted1: false,
            isReacted2: true,
            reactionCount2: this.state.reactionCount2 + 1
          },
          () => {
            this.saveReaction();
          }
        );
      }
    }
  }
  async saveReaction() {
    const { sketch1, sketch2 } = this.props;
    try {
      let _reaction = new DuelVote({
        invitationId: sketch1.attrs.invitationId,
        sketchId: this.state.isReacted1 ? sketch1.attrs._id : sketch2.attrs._id,
        userId: User.currentUser()._id
      });
      await _reaction.save();
    } catch (e) {
      console.error(e);
    }
  }

  resetReaction() {
    if (this.state.isReacted1) {
      this.setState(
        {
          reactionCount1: this.state.reactionCount1 - 1,
          isReacted1: false
        },
        () => {
          this.deleteReaction();
        }
      );
    } else if (this.state.isReacted2) {
      this.setState(
        {
          reactionCount2: this.state.reactionCount2 - 1,
          isReacted2: false
        },
        () => {
          this.deleteReaction();
        }
      );
    }
  }

  async deleteReaction() {
    try {
      let _reaction = await DuelVote.findOne({
        invitationId: this.props.sketch1.attrs.invitationId,
        userId: User.currentUser()._id
      });
      await _reaction.destroy();

      this.updateSketchReactions();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * TODO : Save to owner's db
   */
  updateSketchReactions() {}

  openSignInModal() {
    this.setState({ signInModalOpen: true });
  }
  closeSignInModal() {
    this.setState({ signInModalOpen: false });
  }
  render() {
    const { sketch1, sketch2 } = this.props;
    const { userSession } = this.props;
    return (
      <div className="duel-match-wrapper">
        <SignInModal
          userSession={this.props.userSession}
          modalIsOpen={this.state.signInModalOpen}
          closeModal={this.closeSignInModal.bind(this)}
        />
        <div className="duel-match-info-wrapper">
          <div className="col sketch-word-text-big">{sketch1.attrs.prompt}</div>
          <div className="sketch-mode">{sketch1.attrs.mode}</div>
        </div>
        <div className="duel-match-sketches-wrapper">
          <DuelItem
            userSession={userSession}
            sketch={sketch1}
            deleteSketch={this.props.deleteSketch}
            reactionCount={this.state.reactionCount1}
            selected={this.state.isReacted1}
            handleReact={this.handleReact1.bind(this)}
          />
          <DuelItem
            userSession={userSession}
            sketch={sketch2}
            deleteSketch={this.props.deleteSketch}
            reactionCount={this.state.reactionCount2}
            selected={this.state.isReacted2}
            handleReact={this.handleReact2.bind(this)}
          />
        </div>
      </div>
    );
  }
}
