import React, { Component } from "react";
import DeleteSketchButton from "../SingleSketch/DeleteSketchButton";
import SketchTop from "../SingleSketch/SketchTop";
import SketchBlank from "./SketchBlank";
import DuelReactionBar from "../DuelHome/DuelReactionBar";
import SketchTopBlank from "./SketchTopBlank";
import DuelReactionBlank from "./DuelReactionBlank";

export default class DuelItemBlank extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  render(){
    // console.log(sketch.attrs.username , selected)
    return(
      // <React.Fragment>
      <div className="duel-sketch-card container">
      
        <SketchTopBlank/>
        <SketchBlank/> 
        <DuelReactionBlank
        />
      </div>
    // </React.Fragment>
    )
  }
}