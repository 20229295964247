import React, { Component } from 'react';
import CountDown from '../Shared/CountDown';

export default class PublishCountdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
 componentDidMount(){
 }

 publishSketch(){
  this.props.saveDuelSketch()
 }
  render(){
    return(
      
      <div className="container">
        <div className="row" >
          <div className="col-12" >
            <p>You will be redirected to Home page and <br/> Your sketch will be automatically published in : </p>
            <CountDown
            time='60'
            timesUp={this.publishSketch.bind(this)}
            fontSize='1.5rem'/>
            You can <code style={{color : "#ff6781 "}}>trash</code> this sketch or request for a rematch (<code style={{color : "#ff6781 "}}>retry</code>) NOW.
          </div>
        </div>
      </div>
    )
  }

  
}