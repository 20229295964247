import { Model } from 'radiks';

export default class DuelMatch extends Model {
    static className = 'DuelMatch';
    static schema = {
        invitationId : { type: String, required : true, decrypted: true },
        challenger: { type: String, required : true, decrypted: true }, 
        challengee: {type: String,  required : true, decrypted: true },
        // isDone: { type: Boolean, required : true, decrypted: true },  //if use accepted or denied
    };

    static async createMatch(userSession, invitation, ){
      const match = new DuelMatch({
        invitationId : invitation.attrs._id,
        challenger : invitation.attrs.sender,
        challengee : invitation.attrs.recipient,
      })
      await match.save()
    }
}

