import React, { Component } from "react";
import DuelSketch from "./DuelSketch";
import DuelInvitation from "../../models/DuelInvitation";
import StartCountdownModal from "./StartCountdownModal";
import DuelNoti from "./DuelNoti";
import DuelMatchTop from "./DuelMatchTop";
import DuelStatus from "../../models/DuelStatus";
import DuelMatch from "../../models/DuelMatch";

import ReactLoading from "react-loading";
import moment from "moment";
import SignInModal from "../Shared/SignInModal";
import DuelInvitationRespond from "../../models/DuelInvitationRespond";
import { Link } from "react-router-dom";

const avatarFallbackImage = "/images/DcassoDuel.png";

export default class DuelMatchWrapper extends Component {
  constructor(props) {
    // window.addEventListener('beforeunload', function (e) {
    //   // Cancel the event
    //   e.preventDefault();
    //   // Chrome requires returnValue to be set
    //   e.returnValue = 'You have attempted to leave this page. Are you sure?';
    // });
    // window.onbeforeunload = confirmExit;
    // function confirmExit() {
    //     return "bla bla bla"
    // }
    super(props);
    this.state = {
      isLoading: true,
      matchExpired: false,
      signInModalOpen: false,
      errorHeader: "Uh Oh!",
      errorMessage: "Invalid Invitation",
      invitationPending: true,
      meIsChallenger: false,
      me: {
        username: "You",
        avatarUrl: avatarFallbackImage,
        ready: false
      },
      otherPlayer: {
        username: "Other Player",
        avatarUrl: avatarFallbackImage,
        ready: false
      },
      invitation: {
        attrs: {
          _id: "",
          sender: "",
          recipient: "",
          mode: ""
        }
      },
      bothDone: false,
      startSketch: false
    };
    const inviAcceptCallback = inviAccept => {
      if (
        inviAccept.attrs.invitationId === this.props.match.params.invitationId
      ) {
        this.getInvitationRespond();
      }
    };
    DuelInvitationRespond.addStreamListener(inviAcceptCallback.bind(this));
  }
  async sendExitNoti() {
    const { userSession } = this.props;

    try {
      const noti = new DuelStatus({
        invitationId: this.props.match.params.invitationId,
        username: userSession.loadUserData().username,
        status: "DITCHED"
      });
      await noti.save();
    } catch (e) {
      console.log(e);
    }
  }
  componentDidMount() {
    // window.addEventListener('beforeunload', this.onUnmount, false);

    const { userSession } = this.props;
    if (!userSession.isUserSignedIn()) {
      // this.props.history.push("/");
      this.openSignInModal();
    } else {
      this.getInvitation();
    }
  }

  onUnmount() {
    const { userSession } = this.props;
    if (!this.state.invitationPending) {
      //TODO , and if it's not posted case
      DuelStatus.sendExitNoti(
        userSession,
        this.props.match.params.invitationId
      );
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('beforeunload', this.onUnmount, false);
    // this.onUnmount()
  }

  /**
   * first function to call when load invitation page
   * get invitation info from id from URL
   *
   */
  async getInvitation() {
    const { userSession } = this.props;
    if (this.props.match.params.invitationId) {
      let invitation = await DuelInvitation.findById(
        this.props.match.params.invitationId
      );
      if (invitation) {
        //second check if invitation is still valid
        if (this.checkValidInvitation(invitation.attrs.createdAt)) {
          this.setState({
            isLoading: false,
            matchExpired: true,
            errorHeader: "🤯 Too Slow!",
            errorMessage:
              "Your invitation has expired. Please return home to send another invitation."
          });
        } else {
          //if invitation is valid -> then set invitation to state
          this.setState(
            {
              invitation: invitation
            },
            () => {
              if (this.checkMyInvitation()) {
                this.getInvitationRespond();
              } else {
                this.setState({
                  isLoading: false,
                  matchExpired: true,
                  errorHeader: "🤫 I see what ya doing.",
                  errorMessage:
                    "Don't be a thief; Don't steal someone else's invitation."
                });
              }
            }
          );
        }
      } else {
        this.setState({
          isLoading: false,
          matchExpired: true,
          errorHeader: "🤭 Hello fellow artist!",
          errorMessage:
            "Your invitation link is invalid. We understand that you don't have friends but you don't need to make up a link just to play with someone."
        });
        // this.props.history.push("/");
      }
    }
  }

  checkMyInvitation() {
    const { userSession } = this.props;
    if (
      userSession.loadUserData().username !==
        this.state.invitation.attrs.sender &&
      userSession.loadUserData().username !==
        this.state.invitation.attrs.recipient
    ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * helper for step 2 to check if invitation is valid
   * if invitation was created more than 10 minutes -> invalid
   * @param {*} createdAt
   */
  checkValidInvitation(createdAt) {
    if (moment().diff(moment(createdAt), "minutes") <= 100) {
      return false;
    }
    return true;
  }

  /**
   * finally, last step
   * set players info if match was valid + accepted
   */
  setPlayersInfo() {
    const { invitation } = this.state;
    const { userSession } = this.props;
    if (invitation.attrs.sender === userSession.loadUserData().username) {
      this.setState({
        isLoading: false,
        meIsChallenger: true, //are u the challenger
        me: {
          username: invitation.attrs.sender
        },
        otherPlayer: {
          username: invitation.attrs.recipient
        }
      });
    } else if (
      invitation.attrs.recipient === userSession.loadUserData().username
    ) {
      this.setState({
        isLoading: false,
        meIsChallenger: false,
        me: {
          username: invitation.attrs.recipient
        },
        otherPlayer: {
          username: invitation.attrs.sender
        }
      });
    }
  }

  /**
   * third, if invittion is valid but was not accepted
   * : first check if user accepted
   * if not, show spinning + accept modal
   * if yes, show match
   */
  async getInvitationRespond() {
    const { userSession } = this.props;

    const match = await DuelMatch.findOne({
      invitationId: this.props.match.params.invitationId
    });
    const respond = await DuelInvitationRespond.findOne({
      invitationId: this.props.match.params.invitationId
    });
    //if hasn't accepted
    if (!match && !respond) {
      this.setState({
        isLoading: false
      });
      //if it's the inviter -> don't show accept or deny but show waiting message
      //deny
    } else if (!match && !respond.attrs.accept) {
      this.setState({
        isLoading: false,
        matchExpired: true,
        errorHeader: "🥺 Aw Shucks...",
        errorMessage: "This invitation was denied and is no longer valid."
      });
    } else {
      const status = await DuelStatus.fetchOwnList({
        invitationId: this.props.match.params.invitationId
      });
      if (status.length > 0) {
        if (status[0].attrs.status !== "NOT READY") {
          this.setState({
            isLoading: false,
            matchExpired: true,
            errorHeader: "🙄 Uh oh...",
            errorMessage: "You cannot come back to a started match."
          });
          DuelStatus.sendExitNoti(
            userSession,
            this.props.match.params.invitationId
          );
          return;
        } 
      }
      this.setState({
        // isLoading: false,
        invitationPending: false
      });
      this.setPlayersInfo();
    }
  }

  acceptInvitation() {
    const { userSession } = this.props;
    const { invitation } = this.state;
    DuelInvitationRespond.acceptInvitation(userSession, invitation).finally(
      () => {
        DuelMatch.createMatch(userSession, invitation);
      }
    );
  }
  denyInvitation() {
    const { userSession } = this.props;
    DuelInvitationRespond.denyInvitation(
      userSession,
      this.state.invitation
    ).finally(() => this.props.history.push("/"));
  }
  startSketch() {
    this.setState({
      startSketch: false
    });
    this.setState({
      startSketch: true
    });
  }

  setBothDone(value) {
    this.setState({
      bothDone: value
    });
  }

  openSignInModal() {
    this.setState({ signInModalOpen: true });
  }
  closeSignInModal() {
    this.setState({ signInModalOpen: false });
  }
  render() {
    const { person, me, otherPlayer, invitation, meIsChallenger } = this.state;
    const { userSession } = this.props;
    return (
      <React.Fragment>
        <SignInModal
          userSession={this.props.userSession}
          modalIsOpen={this.state.signInModalOpen}
          closeModal={this.closeSignInModal.bind(this)}
        />
        {this.state.isLoading ? (
          <div style={{ display: "inline-block", textAlign: "center" }}>
            <ReactLoading
              type="bubbles"
              color="#3700FF"
              height={"100%"}
              width={"100%"}
            />
            <p>Searching for your match</p>
          </div>
        ) : this.state.matchExpired ? (
          <div style={{}}>
            <div
              style={{
                width: "50%",
                display: "inline-block",
                padding: "100px 0px 30px 0px"
              }}
            >
              <h1>{this.state.errorHeader}</h1>
              <div>{this.state.errorMessage}</div>
              <Link to="/">
                <button className="btn">return to Home</button>
              </Link>
            </div>
          </div>
        ) : this.state.invitationPending ? (
          invitation.attrs.sender === userSession.loadUserData().username ? (
            <h3>
              Please patiently wait for your opponent to accept the invitation
            </h3>
          ) : (
            <div style={{ display: "inline-block" }}>
              <div>
                <div
                  className="photos"
                  style={{
                    backgroundImage: `url(${otherPlayer.avatarUrl})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                  }}
                />
                <b>
                  {this.state.invitation.attrs.sender} challenges you to a duel!
                </b>
              </div>
              <div className="">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.acceptInvitation.bind(this)}
                >
                  Accept Invitation
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.denyInvitation.bind(this)}
                  style={{ padding: "15px 30px" }}
                >
                  Deny Invitation
                </button>
              </div>
            </div>
          )
        ) : (
          <div>
            <DuelMatchTop
              userSession={this.props.userSession}
              startSketch={this.startSketch.bind(this)}
              invitation={invitation}
              me={me}
              otherPlayer={otherPlayer}
              setBothDone={this.setBothDone.bind(this)}
              meIsChallenger={meIsChallenger}
            />
            <DuelNoti userSession={this.props.userSession} />
            <DuelSketch
              userSession={this.props.userSession}
              invitation={this.state.invitation}
              startSketch={this.state.startSketch}
              bothDone={this.state.bothDone}
              meIsChallenger={meIsChallenger}
              // openCountdownModal={this.openCountdownModal.bind(this)}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
