import React, { Component } from "react";
import DuelInvitation from "../../models/DuelInvitation";
import InviteModal from "../DuelHome/InviteModal";
import NewSketch from "../NewSketch/NewSketch";
import DuelInviteFeedButton from "./DuelInviteFeedButton";
import DuelFeed from "./DuelFeed";
import WaitingModal from "../DuelHome/WaitingModal";
import DuelInvitationRespond from "../../models/DuelInvitationRespond";
import Modal from "react-modal";
import { CircularProgress } from "@material-ui/core";
import ReactLoading from "react-loading";
import OnlineWrapper from "../Online/OnlineWrapper";
import DuelOnline from "../../models/DuelOnline";

Modal.setAppElement("body");
const customStyles = {
  content: {
    width: "650px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    background: "white",
    border: "none"
  }
};

export default class Duel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sender: "",
      recipient: ".id.blockstack",
      selectedMode: "easy",
      inviteButton: false,
      inviteModalOpen: false,
      waitingModalOpen: false,
      invitation: {
        attrs: {
          _id: "",
          sender: "",
          recipient: ""
        }
      },
      invitationDenied: false,
      sendingInvite: false
    };
    const streamCallback = duelInvitation => {
      if (
        duelInvitation.attrs.recipient ===
        this.props.userSession.loadUserData().username
      ) {
        this.setState({
          inviteModalOpen: true,
          invitation: duelInvitation
        });
      } else {
      }
    };
    DuelInvitation.addStreamListener(streamCallback.bind(this));

    const inviAcceptCallback = inviAccept => {
      if (inviAccept.attrs.invitationId === this.state.invitation._id) {
        if (inviAccept.attrs.accept) {
          this.props.history.push(
            `/invitation/${inviAccept.attrs.invitationId}`
          );
        } else {
          this.closeWaitingModal();
          this.setState({
            invitationDenied: true
          });
        }
      }
    };
    DuelInvitationRespond.addStreamListener(inviAcceptCallback.bind(this));
  }
  componentDidMount() {
    const { userSession } = this.props;
    if (userSession.isUserSignedIn()) {
      this.setState({
        sender: userSession.loadUserData().username
      });
    }
  }

  selectMode(mode) {
    this.setState({
      selectedMode: mode
    });
  }

  async inviteUser() {
    this.setState({
      sendingInvite: true
    });
    try {
      const newInvi = await new DuelInvitation({
        sender: this.state.sender,
        recipient: this.state.recipient,
        mode: this.state.selectedMode
      });
      await newInvi.save();
      this.setState(
        {
          invitation: newInvi,
          sendingInvite: false
        },
        () => this.openWaitingModal()
      );
    } catch (e) {
      console.log(e, "unable to create invite");
    }
  }

  async cancelInvitation() {
    await this.state.invitation.destroy();
    this.closeWaitingModal();
    this.setState({
      invitation: {
        attrs: {
          _id: "",
          sender: "",
          recipient: ".id.blockstack"
        }
      }
    });
  }
  copyAddress() {
    const { invitation } = this.state;
    let link = `https://dcasso-duel.app/invitation/${invitation._id}`;
    var textField = document.createElement("textarea");
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  }
  /**
   * wtf is this function haha
   * @param {*} e
   */
  onToUsernameChange(e) {
    if (e.target.value.length <= 1) {
      this.setState({
        recipient: e.target.value + this.state.recipient
      });
    } else {
      this.setState({
        recipient: e.target.value.toLowerCase()
      });
    }
  }

  openInviteModal() {
    this.setState({ inviteModalOpen: true });
  }
  closeInviteModal() {
    this.setState({
      inviteModalOpen: false
    });
  }

  openWaitingModal() {
    this.setState({ waitingModalOpen: true });
  }
  closeWaitingModal() {
    this.setState({ waitingModalOpen: false });
  }

  openInviteButton() {
    this.setState({ inviteButton: true });
  }
  closeInviteButton() {
    this.setState({
      inviteButton: false,
      invitationDenied: false
    });
  }
  signIn() {
    const { userSession } = this.props;
    userSession.redirectToSignIn();
  }

  inviteActive(username) {
    this.setState({
      recipient: username
    });
    this.openInviteButton();
  }
  render() {
    const { userSession } = this.props;

    return (
      <div>
        {userSession.isUserSignedIn() && (
          <React.Fragment>
            <div style={{ width: "100%" }}>
              <button
                className="btn btn-primary button-start bold-weight btn-new-sketch"
                onClick={this.openInviteButton.bind(this)}
              >
                Invite your fellow artist friend
              </button>
              {/* <div className="phone-only">
                <div className="dropdown">
                  <span
                    className="dropdown-toggle"
                    // type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    See active artists <i className="fas fa-chevron-down"></i>
                  </span>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                  </div>
                </div>
              </div> */}
            </div>
            <InviteModal
              modalIsOpen={this.state.inviteModalOpen}
              closeModal={this.closeInviteModal.bind(this)}
              userSession={this.props.userSession}
              invitation={this.state.invitation}
            />
            <Modal
              isOpen={this.state.inviteButton}
              onRequestClose={this.closeInviteButton.bind(this)}
              style={customStyles}
              contentLabel="Invite"
            >
              {!this.state.waitingModalOpen ? (
                <div className="container">
                  <div className="invite-modal-wrapper">
                    <h3>Send a Duel Invite!</h3>
                    {this.state.invitationDenied && (
                      <small className="text-danger">
                        😨 Oops, your invitation was denied
                      </small>
                    )}

                    <div className="invite-description">
                      <small>Enter a Blockstack ID</small>
                    </div>
                    <input
                      className="form-control"
                      placeholder="ex: pablopicasso.id.blockstack"
                      id="invitation-input"
                      value={this.state.recipient}
                      onChange={this.onToUsernameChange.bind(this)}
                    />
                    <div className="invite-description">
                      <small>Select a mode</small>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <button
                        className={
                          "btn btn-light " +
                          (this.state.selectedMode === "easy" ? "active" : "")
                        }
                        onClick={this.selectMode.bind(this, "easy")}
                      >
                        Easy
                      </button>
                      <button
                        className={
                          "btn btn-light " +
                          (this.state.selectedMode === "medium" ? "active" : "")
                        }
                        onClick={this.selectMode.bind(this, "medium")}
                      >
                        Medium
                      </button>
                      <button
                        className={
                          "btn btn-light " +
                          (this.state.selectedMode === "hard" ? "active" : "")
                        }
                        onClick={this.selectMode.bind(this, "hard")}
                      >
                        Hard
                      </button>
                    </div>
                    <button
                      disabled={
                        this.state.recipient.trim() === "" ||
                        this.state.recipient ===
                          userSession.loadUserData().username ||
                        this.state.sendingInvite
                      }
                      className="btn btn-primary"
                      onClick={this.inviteUser.bind(this)}
                    >
                      {this.state.sendingInvite ? "Sending..." : "Invite"}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h5
                        className="modal-title"
                        style={{ fontFamily: "Source Code Pro,monospace" }}
                      >
                        Waiting for{" "}
                        <code style={{ color: "#ff6781 " }}>
                          {this.state.invitation.attrs.recipient}
                        </code>{" "}
                        to accept the invitation
                      </h5>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ReactLoading
                          type="bubbles"
                          height={"20%"}
                          width={"20%"}
                          color={"#3700FF"}
                        />
                      </div>

                      <form style={{ cursor: "pointer" }}>
                        <label
                          className="invite-description"
                          style={{ fontSize: "0.7rem" }}
                        >
                          An invitation was sent to{" "}
                          {this.state.invitation.attrs.recipient}. Make sure
                          they have their browser open <br />
                          or Copy this <u>Invitation Link</u> and send to them
                          <input
                            className="form-control"
                            type="text"
                            readonly
                            id="invitationId"
                            style={{ width: "600px" }}
                            value={
                              `https://dcasso-duel.app/invitation/` +
                              this.state.invitation._id
                            }
                            onClick={this.copyAddress.bind(this)}
                          />
                        </label>
                      </form>
                      <small>
                        Invitation will expire in 10 minutes if your opponent
                        does not accept it.
                      </small>
                      <button
                        className="btn btn-light"
                        onClick={this.cancelInvitation.bind(this)}
                      >
                        Cancel invitation
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Modal>
          </React.Fragment>
        )}
        <button
          className="navbar-toggler"
          id="online-toggle"
          type="button"
          data-toggle="collapse"
          data-target="#online-bar"
          aria-controls="online-bar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          See active artists <i className="fas fa-chevron-down"></i>
        </button>
        <div>
          <div className="collapse" id="online-bar">
            <div className="online-bar">
              <OnlineWrapper
                userSession={this.props.userSession}
                inviteActive={this.inviteActive.bind(this)}
              />
            </div>
          </div>
          <div>
            <DuelFeed userSession={this.props.userSession} />
          </div>
        </div>
      </div>
    );
  }
}
