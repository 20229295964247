import React, { Component } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import ReactLoading from "react-loading";

Modal.setAppElement("body");
const customStyles = {
  content: {
    width: "600px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "30px"
  }
};
export default class WaitingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  copyAddress() {
    const { invitation } = this.props;

    console.log(invitation.attrs._id);
  }

  render() {
    const { invitation } = this.props;
    console.log(invitation);
    return (
      <Modal
        isOpen={this.state.inviteButton}
        onRequestClose={this.closeInviteButton.bind(this)}
        style={customStyles}
        contentLabel="Invite"
      >
        {!this.state.waitingModalOpen ? (
          <div className="container">
            <div className="invite-modal-wrapper">
              <h2>Send a Duel invite!</h2>
              <div className="invite-description">
                Enter a Blockstack ID to challenge them to a dcasso duel
              </div>
              <input
                className="form-control"
                placeholder="ex: dcassoduel.id.blockstack"
                value={this.state.toUsername}
                onChange={this.onToUsernameChange.bind(this)}
              />
              Select a mode
              <div style={{ display: "inline-block" }}>
                <button className="btn btn-light">Easy</button>
                <button className="btn btn-light">Medium</button>
                <button className="btn btn-light">Hard</button>
              </div>
              <button
                className="btn btn-primary"
                onClick={this.inviteUser.bind(this)}
              >
                Invite
              </button>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h5 className="modal-title">
                  Waiting for {this.state.invitation.attrs.recipient} to accept
                  the invitation
                </h5>
                <form>
                  <label>
                    Copy Invitation Link
                    <input
                      className="form-control"
                      type="text"
                      readonly
                      id="invitationId"
                      value="test"
                      onClick={this.copyAddress.bind(this)}
                    />
                  </label>
                </form>
                {/* <CircularProgress style={{ color: "#3700FF" }} /> */}
                <ReactLoading
                  type="bubbles"
                  color="#3700FF"
                  height={"100%"}
                  width={"100%"}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
