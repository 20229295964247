import React, { Component } from "react";

export default class Intro extends Component {
  render(){
    return(
      <div className="nav-item ">
      <button type="button" className="nav-link dropdown-toggle btn btn-primary btn-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        How to Dcasso Duel
      </button>
      <div className="dropdown-menu dropdown-menu-right" style={{padding : '20px', position : 'absolute'}}>
        <code>1. Insert a Blockstack ID of a friend. Invite. Wait for response</code><br/>
        <code>2. When on match screen, select READY when you're ready.</code><br/>
        <code>3. When done, select PUBLISH sketch (or DISCARD & QUIT).</code>
      </div>
      </div>
    )
  }
}