import React, { Component } from 'react';

const buttonStyles = ' reaction-button btn-reaction btn-reaction-clicked';
const leftButton = ' reaction-button-left-side';
const centerButton = ' reaction-button-center';
const rightButton = ' reaction-button-right-side';
const selectedClass = ' btn-reaction-selected';

export default class ReactionBar extends Component {
  handleReact(reaction) {
    this.props.onReact(reaction);
  }

    render() {
        // const { sketch } = this.props;
        // const { loveCount, laughCount, poopCount } = this.props;
        return (
            <div className="reaction-bar-wrapper">
                <div className="reaction-button-wrapper">
                    {/* <button type="button" className="btn btn-outline-dark btn-sm btn-reaction btn-reaction-clicked" data-toggle="tooltip" title="Love" */}
                    <button className={this.props.selected === 'love' ? buttonStyles +  selectedClass + leftButton: buttonStyles + leftButton} data-toggle="tooltip" title="Love"
                        onClick={() => this.props.onReact('love')}>
                        <i className="far fa-grin-hearts fa-2x"></i>
                        {this.props.loveCount}
                    </button>
                </div>
                <div className="reaction-button-wrapper">
                    <button className={this.props.selected === 'laugh' ? buttonStyles +  selectedClass + centerButton : buttonStyles + centerButton}  data-toggle="tooltip" title="Laugh"
                        onClick={() => this.props.onReact('laugh')}>
                        <i className="far fa-grin-squint-tears fa-2x"></i>
                        {this.props.laughCount}
                    </button>
                </div>
                <div className="reaction-button-wrapper">
                    <button className={this.props.selected === 'poop' ? buttonStyles +  selectedClass + rightButton : buttonStyles + rightButton}  data-toggle="tooltip" title="Poop" 
                    onClick={() => this.props.onReact('poop')}>
                        <i className="fas fa-poo fa-2x"></i>
                        {this.props.poopCount}</button>
                </div>
            </div>
        )
    }
}