import React, { Component } from 'react';
import {
  Person,
} from 'blockstack';
import SingleSketch from '../SingleSketch/SingleSketch';
import NewSketchButton from '../Shared/NewSketchButton';
import { withRouter } from "react-router";
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackBarWrapper from '../Shared/SnackBarWrapper';
import ProfilePic from './ProfilePic';

const avatarFallbackImage = '/images/DcassoDuel.png';

 class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person: {
        name() {
          return 'Anonymous';
        },
        avatarUrl() {
          return avatarFallbackImage;
        },
      },
      mySketches: [],
      loveReacts: 0,
      laughReacts: 0,
      poopReacts: 0,
      isLoading: false,
      open : false

    };
  }

  async getMyReacts() {
    let _mySketches = this.state.mySketches;
    let _loves = 0;
    let _laughs = 0;
    let _poops = 0;
    try {
      _mySketches.forEach(sketch => {
        _loves += sketch.attrs.loveCount;
        _laughs += sketch.attrs.laughCount;
        _poops += sketch.attrs.poopCount;
      })
      this.setState({
        loveReacts: _loves,
        laughReacts: _laughs,
        poopReacts: _poops,
      })
    } catch (e) {
      console.log(e, 'failed to get your reacts')
    }
  }

  // async getMySketches() {
  //   try {
  //     this.setState({ isLoading: true })
  //     let _mySketches = await Sketch.fetchOwnList({ sort: "-createdAt"})
  //     await _mySketches.forEach(sketch => {
  //       this.updateMySketchReaction(sketch)
  //     })
  //     this.setState({
  //       mySketches: _mySketches,
  //       isLoading: false,
  //     })
  //   } catch (e) {
  //     console.log(e, 'Failed to get you sketches.')
  //     this.setOpen()
  //   }
  // }

  setOpen(){
    this.setState({
      open : true
    })
  }

  handleCloseSnackBar(){
    this.setState({
      open : false
    })
  }
  
  async deleteSketch(item){
    try {
      await item.destroy()
      let _sketches = this.state.mySketches.filter(sketch => sketch.attrs._id !== item.attrs._id)
      this.setState({
        mySketches  : _sketches
      })
    } catch(e){
      console.log("unable to delete")
    }
    
  }

  // async updateMySketchReaction(_sketch) {
  //   try {
  //     let _loveReaction = await Reaction.count({ sketchId: _sketch.attrs._id, type: 'love' })
  //     let _laughReaction = await Reaction.count({ sketchId: _sketch.attrs._id, type: 'laugh' })
  //     let _poopReaction = await Reaction.count({ sketchId: _sketch.attrs._id, type: 'poop' })
  //     await _sketch.update({
  //       loveCount : _loveReaction,
  //       laughCount : _laughReaction,
  //       poopCount  : _poopReaction
  //     })
  //     await _sketch.save()
  //   } catch (e) {
  //     console.log(e, 'Failed to update sketch.', _sketch.attrs._id)
  //   }
  // }

  render() {
    const { handleSignOut, userSession } = this.props;
    const { person } = this.state;
    return (
      <React.Fragment>
        <div className="profile-container">
        <div className="panel-welcome" id="section-2">
          <div className="avatar-section" align="center">
          <ProfilePic person = {person}/>
          {/* < div className="avatar" 
              style={{
              backgroundImage: `url(${ person.avatarUrl() ? person.avatarUrl() : avatarFallbackImage })` ,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'}}/> */}
          </div>
          <h1 className="profile-h1">Hello, <span id="heading-name">{person.name() ? person.name() : 'Nameless Person'}</span>!</h1>
          <p className="lead">

          </p>
        </div>
        {!this.state.isLoading ?
          <div>
            {this.state.mySketches.length !== 0 ?
              <div>
                <div className="reaction-row">
                  <div>
                    <div className="">
                      <i className="far fa-grin-hearts fa-2x"></i>
                      <div>{this.state.loveReacts}</div>
                    </div>
                    <div className="">
                      <i className="far fa-grin-squint-tears fa-2x"></i>
                      <div>{this.state.laughReacts}</div>
                    </div>
                    <div className="">
                      <i className="fas fa-poo fa-2x"></i>
                      <div>{this.state.poopReacts}</div>
                    </div>
                  </div>
                </div>
                <div className="home-sketch-wrapper">
                  <div >
                    {this.state.mySketches.map((sketch) => (
                      <SingleSketch 
                        sketch={sketch} 
                        key={sketch.attrs._id}
                        userSession={this.props.userSession} 
                        deleteSketch={this.deleteSketch.bind(this)} 
                      />
                    ))}
                  </div>
                </div>
              </div>
              : <div>
                <div>You don't have any sketch yet ;)</div>
                <NewSketchButton userSession={userSession}/>

              </div>

            }
          </div> :
            <div>
            <CircularProgress style={{color : "#3700FF"}} />
            <span>Loading...</span>
            </div>
        }
        <SnackBarWrapper
        message = "There is an error. Please try again later"
        open = {this.state.open}
        variant = "Error"
        onClose = {this.handleCloseSnackBar.bind(this)}/>
        </div>
        
      </React.Fragment>
    );
  }

  // componentWillMount() {
  //   const { userSession } = this.props;
  //   if (!userSession.isUserSignedIn()) {
  //     this.props.history.push('/signin')
  //   } else {
  //     this.setState({
  //       mySketches : this.props.mySketches,
  //       person: new Person(userSession.loadUserData().profile),
  //     });
  //   }
  // }
  async componentDidMount() {
    const { userSession } = this.props;
    if (!userSession.isUserSignedIn()) {
      this.props.history.push('/signin')
    } else {
      await this.setState({
        mySketches : this.props.mySketches,
        person: new Person(userSession.loadUserData().profile),
      }, () => this.getMyReacts());
    }
    // this.getMySketches()
    // .finally(() => { this.setState({ isLoading: false }) })
    // .then(() => { this.getMyReacts() })
  }
  componentDidUpdate(prevProps){
    if (prevProps.mySketches !== this.props.mySketches) {
      this.setState({
        mySketches : this.props.mySketches,
      }, () => this.getMyReacts());
    }
  }
}

export default withRouter(Profile)