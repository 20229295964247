import React, { Component } from 'react';
import SingleSketch from '../SingleSketch/SingleSketch';
import Sketch from '../../models/Sketch';
import SortInput from './SortInput.js';
import SnackBarWrapper from '../Shared/SnackBarWrapper';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import Reaction from '../../models/Reaction';

export default class HomeSketchFeed extends Component {
  limit = '500';
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sketches: [],
      input: '',
      open: false,
      mostRecent: [],
      mostReacted: []
    }
  }

  componentDidMount() {
    this.fetchData();
    // this.sortByMostReacted()

  }

  componentDidUpdate(prevProps) {
    if (prevProps.reaction !== this.props.reaction) {
      this.changeReaction();
    }

    // if (prevProps.filter !== this.props.filter) {
    //   this.sortByMode(this.props.filter)
    // }
  }

  changeReaction() {
    if (this.props.reaction === 'mostReacted') {
      if (this.state.mostReacted.length === 0) {
        this.sortByMostReacted()
      }
      this.setState({
        sketches: this.state.mostReacted
      })
    } else {
      this.setState({
        sketches: this.state.mostRecent
      })
    }
    // if (this.props.reaction === '' || this.props.reaction === 'newest') {
    //   this.getRecentSketches();
    // } else if (this.props.reaction === 'oldest') {
    //   this.getOldestSketches();
    // } else if (this.props.reaction === 'mostReacted') {
    //   if (this.state.mostReacted.length === 0) {
    //     this.sortByMostReacted()
    //   }
    //   this.setState({
    //     sketches : this.state.sortedSketchesByReaction
    //   })
    // } else if (this.props.reaction === 'mostLoved') {
    //   this.fetchByMostLoved();
    // } else if (this.props.reaction === 'mostLaughed') {
    //   this.fetchByMostLaughed();
    // } else if (this.props.reaction === 'mostPooped') {
    //   this.fetchByMostPooped();
    // } else {
    //   this.getRecentSketches(); //just in case
    // }
  }



  fetchData() {
    this.setState({ isLoading: true });
    this.getAllModeRecentSketches().finally(() => { this.setState({ isLoading: false }) }) //since we dont query by mode rn
  }

  /**
   * will be named to fetchByMostRecent - cuz i like so
   */

  async getAllModeRecentSketches() {
    try {
      let _sketches = await Sketch.fetchList({ sort: '-createdAt', limit: this.limit }, { decrypt: false });

      this.setState({
        sketches: _sketches,
        mostRecent: _sketches
      });
    } catch (e) {
      this.setOpen();

    }
  }

  async getRecentSketches() {
    // this.setState({ sketches: [] });
    //anh removed mode: this.props.filter
    let _sketches = await Sketch.fetchList({ sort: '-createdAt', limit: this.limit }, { decrypt: false });
    this.setState({
      sketches: _sketches,
      mostRecent: _sketches
    });
  }

  async getOldestSketches() {
    // this.setState({ sketches: [] });
    let _sketches = await Sketch.fetchList({ mode: this.props.filter, sort: 'createdAt', limit: this.limit }, { decrypt: false });
    this.setState({ sketches: _sketches });
  }

  async fetchByMostReacted() {
    // this.setState({ sketches: [] });
    let _sketches = await Sketch.fetchList({ mode: this.props.filter, sort: '-reactionCount', limit: this.limit }, { decrypt: false });
    this.setState({ sketches: _sketches });
  }

  async fetchByMostLoved() {
    // this.setState({ sketches: [] });
    let _sketches = await Sketch.fetchList({ mode: this.props.filter, sort: '-loveCount', limit: this.limit }, { decrypt: false });
    this.setState({ sketches: _sketches });
  }

  async fetchByMostLaughed() {
    // this.setState({ sketches: [] });
    let _sketches = await Sketch.fetchList({ mode: this.props.filter, sort: '-laughCount', limit: this.limit }, { decrypt: false });
    this.setState({ sketches: _sketches });
  }

  async fetchByMostPooped() {
    // this.setState({ sketches: [] });
    let _sketches = await Sketch.fetchList({ mode: this.props.filter, sort: '-poopCount', limit: this.limit }, { decrypt: false });
    this.setState({ sketches: _sketches });
  }

  async sortByMostReacted() {
    let _reactions = await Reaction.fetchList({}, { decrypt: false });

    let sortedSketches = await _.values(_.reduce(_reactions, function (result, obj) {
      var _sketchId = obj.attrs.sketchId;
      result[_sketchId] = {
        sketchId: _sketchId,
        count: (result[_sketchId] ? result[_sketchId].count : 0) + 1
      };
      return result;
    }, {}));
    await sortedSketches.sort((a, b) => b.count - a.count)
    let _sortByReaction = []
    sortedSketches.forEach(async sketch => {
      let _sketch = await Sketch.findById(sketch.sketchId, { decrypt: false })
      await _sortByReaction.push(_sketch);
    })
    await this.setState({
      mostReacted: _sortByReaction
    })
  }

  doneGettingReactions() {
    this.sortByMostReacted()
  }
  /**
   * 
   * @param {*} _mode : type string. 'easy','medium','hard'
   */
  sortByMode(_mode) {
    // console.log(_mode)
    if (this.props.reaction === 'mostReacted') {
      if (_mode === "") {
        let _sketches = this.state.mostRecent.filter((sketch) => {
          return sketch.attrs.mode === _mode
        })
        // console.log(this.state.mostRecent)
        this.setState({ sketches: _sketches });

      } else {
        this.setState({
          sketches: this.state.mostRecent
        })
      }

    } else {
      if (_mode) {
        let _sketches = this.state.mostReacted.filter((sketch) => {
          return sketch.attrs.mode === _mode
        })
        // console.log(this.state.mostRecent)
        this.setState({ sketches: _sketches });

      } else {
        this.setState({
          sketches: this.state.mostReacted
        })
      }

    }


  }

  async deleteSketch(item) {
    try {
      await item.destroy()
      let _sketches = this.state.sketches.filter(sketch => sketch.attrs._id !== item.attrs._id)
      this.setState({
        sketches: _sketches
      })
    } catch (e) {
      console.log("unable to delete")
      this.setOpen();

    }

  }
  setOpen() {
    this.setState({
      open: true
    })
  }
  handleCloseSnackBar() {
    this.setState({
      open: false
    })
  }


  render() {
    const list = this.state.sketches
      .filter(d => (d !== undefined) && (this.props.input === '' || d.attrs.prompt.includes(this.props.input)))
      .map((d, index) => {
        // console.log(d)
        return <SingleSketch
          key={d.attrs._id}
          sketch={d}
          deleteSketch={this.deleteSketch.bind(this)}
          userSession={this.props.userSession} />
      });
    return (

      <div className="home-sketch-wrapper">
        {this.state.isLoading &&
          <span className="loading-center">
            <CircularProgress style={{ color: "#3700FF" }} />
            <span>    Loading...</span>
          </span>}
        {!this.state.isLoading && this.state.sketches.length !== 0 ?
          <div>
            {list}
          </div>
          :
          !this.state.isLoading &&
          <div> <p>No Sketches to display</p></div>

        }
        <SnackBarWrapper
          message="There is an error. Please try again later"
          open={this.state.open}
          variant="Error"
          onClose={this.handleCloseSnackBar.bind(this)} />
      </div>
    );
  }
}