import React, { Component } from 'react';
import ColorButton from './ColorButton';

const color1 = 'black';
const color2 = 'blue';
const color3 = 'green';
const color4 = 'red';
const color5 = 'yellow';

export default class BasicColorPicker extends Component {
    

    render() {
        return (
            <div className="color-picker-wrapper">
                <button value={color1} className={'btn color-button '+  (this.props.brushColor === 'black' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color1,}}>
                </button>
                <button value={color2} className={'btn color-button '+  (this.props.brushColor === 'blue' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color2,}}>
                </button>
                <button value={color3} className={'btn color-button '+  (this.props.brushColor === 'green' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color3,}}>
                </button>
                <button value={color4} className={'btn color-button '+  (this.props.brushColor === 'red' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color4,}}>
                </button>
                <button value={color5} className={'btn color-button '+  (this.props.brushColor === 'yellow' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color5,}}>
                </button>
            </div>
        )
    }
}