import React, { Component } from 'react';

export default class SortButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reaction: '',
      filter: '',
    }
  }
  // change(e){
  //   console.log(e.target.value);
  //   this.props.setReaction(e.target.value);
  //   // this.setState({value: event.target.value});
  // }

  render() {
    return (
      <div>
        
        {/* i used external library https://developer.snapappointments.com/bootstrap-select/examples/ */}
        <select className="selectpicker" title="Sort by"
        onChange={this.props.setReaction}>
        <option value="newest" defaultValue>Most Recent (Default)</option>
        <option value="mostReacted">Most Reacted</option>
        {/* <option value="oldest">Oldest</option> */}
        {/* <option value="mostLoved">Reaction : Most 😍</option>
        <option value="mostLaughed">Reaction : Most 😂</option>
        <option value="mostPooped">Reaction : Most 💩</option>
        <option value="leastLoved">Reaction : Least 😍</option>
        <option value="leastLaughed">Reaction : Least 😂</option>
        <option value="leastPooped">Reaction : Least 💩</option> */}
        </select>
        {/* <select className="selectpicker" title="Filter by"
        onChange={this.props.setFilter} >
        <option value="">None</option>
        <option value="easy">Mode : Easy</option>
        <option value="medium">Mode : Medium</option>
        <option value="hard">Mode : Hard</option>
        </select> */}
      </div>
    )
  }
}