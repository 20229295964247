import React, { Component } from "react";
import Modal from "react-modal";
import DuelRematchRespond from "../../models/DuelRematchRespond";
import { withRouter } from "react-router";
import DuelNotification from "../../models/DuelNotification";
import DuelStatus from "../../models/DuelStatus";

Modal.setAppElement("body");
const customStyles = {
  content: {
    width: "600px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    zIndex: "16"
    // background            : 'none',
    // border                : 'none',
  }
};
class ExitWarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
  }

  async sendExitNoti() {
    const { userSession } = this.props;
    DuelStatus.sendExitNoti(userSession , this.props.match.params.invitationId)
    .finally(()=>{
      this.props.closeModal();
      this.props.history.push("/");
    })
  }
  exit() {
    this.sendExitNoti();
  }
  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        // contentLabel="Log In"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="modal-title">Exit Warning</h1>
              <p>
                Are you sure you want to exit? Your sketch will not be saved or
                recovered
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.props.closeModal}
              >
                Cancel
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-danger"
                onClick={this.exit.bind(this)}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default withRouter(ExitWarningModal);
