import React, { Component } from 'react';
import TimeStamp from '../Shared/timestamp.js';
import {
  Person, lookupProfile
} from 'blockstack';
import ProfilePic from '../Profile/ProfilePic.js';


const avatarFallbackImage = '/images/DcassoDuel.png';
export default class SketchTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: {
  	  	name() {
          return 'Dcasso Artist';
        },
  	  	avatarUrl() {
  	  	  return avatarFallbackImage;
  	  	},
      },
      username: "",
      createdAt : ""
    }
  }
  convertDate(){
    const {sketch} = this.props;
    let timeString = TimeStamp.convertDate(sketch.attrs.createdAt);
    this.setState({
      createdAt : timeString.toLowerCase(),
    })

  }
  getProfile(){
    let username = this.props.sketch.attrs.username;

    lookupProfile(username)
        .then((profile) => {
          this.setState({
            userFound :true,
            person: new Person(profile),
            username: username
          })
        })
  }
  render() {
    const {sketch} = this.props;
    const {person} = this.state;
    return(
      <div className="sketch-top-wrapper">

        <div className="photos-wrapper">
          {/* < div className="photos"
              style={{
              backgroundImage: `url(${ person.avatarUrl() ? person.avatarUrl() : avatarFallbackImage })` ,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'}}/> */}
          <ProfilePic person = {person}/>
        </div>
        <div className="top-user-data">

          <div className="">
            {sketch.attrs.username === "" ?
            <div  className="top-username" style={{background : 'whitesmoke', color : 'whitesmoke'}}>
            Dcasso User
          </div> :
            <div  className="top-username">
              {sketch.attrs.username}
            </div>
            }
          </div>
          <br />
          <div className="">
          {sketch.attrs.createdAt === "" ?
            <div  className="top-createdAt" style={{background : 'whitesmoke', color : 'whitesmoke'}}>
            Future
          </div> :

            <div  className="top-createdAt">
              {this.state.createdAt}
            </div>
            }
        {/* <span className="badge badge-pill badge-danger">Challenger</span> */}

          </div>
        </div>
      </div>
    )
  }

  componentDidMount(){
    this.convertDate();
    this.getProfile();
  }
}