import React, { Component } from 'react';
import Modal from 'react-modal';
import CountDown from '../Shared/CountDown';

Modal.setAppElement('body')
const customStyles = {
  content : {
    width : '600px',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '30px',
    background            : 'none',
    border                : 'none',
  }
};
export default class StartCountdownModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
 componentDidMount(){
 }
timesUp(){
  this.props.closeModal()
  this.props.startSketch()
}
  render(){
    return(
      <Modal
      isOpen={this.props.modalIsOpen}	      
      // onRequestClose={this.props.closeModal}	      
      style={customStyles}
      contentLabel="Log In"	    
      >
      <div className="container">
        <div className="row" >
          <div className="col-12" >
            <CountDown
            time='5'
            timesUp={this.timesUp.bind(this)}
            fontSize='5rem'/>
          </div>
        </div>
      </div>
      </Modal>
    )
  }

  
}