import React, { Component } from 'react';
import Sketch from '../../models/Sketch';
import { Model } from 'radiks';
import HomeSketchFeed from './HomeSketchFeed';
import SortButton from './SortButton';
import NewSketchButton from '../Shared/NewSketchButton';
import SortInput from './SortInput';
import SignInNoticeModal from '../Shared/SignInNoticeModal';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sketches: [],
      reaction: '',
      filter: '',
      modalIsOpen : false,
    }
  }
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-6 mx-auto sketch-button-wrapper">
            {/* <button onClick={() => this.deleteMySketches()}>Delete my sketches</button> */}
            <NewSketchButton 
            userSession = {this.props.userSession}
            handleSignIn={ this.handleSignIn }/>
          </div>
        </div>
        <SortButton
          reaction={this.state.reaction}
          filter={this.state.filter}
          setReaction={event => this.setReaction(event)}
          setFilter={event => this.setFilter(event)}
        />
        <div className="row">
          <HomeSketchFeed 
            input = {this.props.input}
            reaction={this.state.reaction} 
            filter={this.state.filter}
            userSession = {this.props.userSession}
            />
        </div>
        <SignInNoticeModal
        modalIsOpen={this.state.modalIsOpen}
        closeModal = {this.closeModal.bind(this)}	      
        />
      </div>
    )
  }
  


  // async deleteMySketches(){
  //   let _sketches = await Sketch.fetchOwnList();
  //   let i = 0
  //   for(i = 0; i < _sketches.length; i++){
  //     _sketches[i].destroy();
  //     console.log(_sketches[i].attrs.prompt);
  //   }
  // }

  setReaction(event){
    this.setState({ reaction: event.target.value });
  }

  setFilter(event){
    this.setState({ filter: event.target.value });
  }
  openModal(){
    this.setState({modalIsOpen: true});	
  }
  closeModal(){
    this.setState({modalIsOpen: false});	
  }
  componentDidMount() {
    const { userSession } = this.props;

    if (!userSession.isUserSignedIn()){
      setTimeout(() => {
        this.openModal()
      }, 5000);
    }
  }

}