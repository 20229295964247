import { Model } from 'radiks';

export default class Reaction extends Model {
    static className = 'Reaction';
    static schema = {
        sketchId: { type: String, decrypted: true }, 
        userId: {type: String, decrypted: true },
        type: { type: String, decrypted: true }, //type : love , laugh, poop
    };
    static defaults = {
    };
}

