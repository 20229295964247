import React, { Component } from "react";
import DeleteSketchButton from "../SingleSketch/DeleteSketchButton";
import SketchTop from "../SingleSketch/SketchTop";
import SketchBlank from "../Blank/SketchBlank";
import DuelReactionBar from "./DuelReactionBar";
import DuelItemBlank from "../Blank/DuelItemBlank";

export default class DuelItem extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  /**
   * helper to check if user is viewing their own sketch
   */
  isLocal() {
    const { sketch, userSession } = this.props;
    if (userSession.isUserSignedIn()) {
      // return true;
      return userSession.loadUserData().username === sketch.attrs.username
    }
    return false;
  }

  render(){
    const {sketch ,reactionCount, selected} = this.props
    // console.log(sketch.attrs.username , selected)
    return(
      <React.Fragment>
        {sketch ? 
      <div className="duel-sketch-card container">
        {this.isLocal() && (
          <DeleteSketchButton
            deleteSketch={this.props.deleteSketch}
            sketch={sketch}
          />
        )}
        <SketchTop sketch={sketch} />
        {sketch.attrs.binary === "" ? 
        <SketchBlank/> : 
        <img
          src={sketch.attrs.binary}
          style={{ background: "white", width: "100%" }}
        />
        }
        <DuelReactionBar
          reactionCount={reactionCount}
          selected={selected}
          onReact={this.props.handleReact}
        />
      </div>
      : <DuelItemBlank/>}
    </React.Fragment>
    )
  }
}