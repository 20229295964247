import React, { Component } from 'react';
export default class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time : 0
    }
  }
 componentDidMount(){
   const {time} = this.props

   this.setState({
     time : time
   })
   this.handleInterval = setInterval((e) => {
     this.startTimer(e)
    }, 1000);
 }
 handleInterval
 startTimer(){
   if (this.state.time > 0) {
    this.setState({
      time : this.state.time - 1
    })
  
   }else {
     this.stopTimer()
   }
 }
 stopTimer(){
  clearInterval(this.handleInterval);
  this.setState({
    time: 0,
  }, this.props.timesUp())
}
  render(){
    const {time, fontSize} = this.props
    return(
      <h1 className="modal-title" style={{fontSize : fontSize}}>{this.state.time}</h1> 
    )
  }

  
}