import { Model } from 'radiks';

export default class DuelInvitation extends Model {
    static className = 'DuelInvitation';
    static schema = {
        sender: { type: String, required : true, decrypted: true }, 
        recipient: {type: String,  required : true, decrypted: true },
        mode : { type: String, decrypted: true },
        prompt : { type: String, decrypted: true },
    };
    static defaults = {
      mode : "easy"
    };
}

