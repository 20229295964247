import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import DuelInviteListItem from "./DuelInviteListItem";
import DuelInvitation from "../../models/DuelInvitation";
import Modal from "react-modal";
import { Person } from "blockstack";

Modal.setAppElement("body");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "600px",
    height: "500px",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    boxShadow: "0 5px 15px rgba(153, 153, 153, 0.2)",
    borderRadius: "10px"
  }
};

export default class DuelInviteFeedButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: {
        id: ""
      },
      userInvites: [],
      isLoading: false,
      modalIsOpen: false,
      invitesButtonStyle: "no-invites-style"
    };
  }

  async getUserInvites() {
    let _invites = await DuelInvitation.fetchList({
      recipient: this.state.person.id,
      sort: "-createdAt"
    });
    // get tales from radiks with own id
    if (_invites[0] !== undefined) {
      //if the list even has 1 element
      this.setState(
        {
          userInvites: _invites
        },
        () => {
          this.setInviteButtonStyle();
        }
      );
    }
  }

  setInviteButtonStyle() {
    if (this.state.userInvites.length !== 0) {
      this.setState({ invitesButtonStyle: "invites-style" });
    } else {
      this.setState({ invitesButtonStyle: "no-invites-style" });
    }
  }

  componentDidMount() {
    const { userSession } = this.props;
    this.setState(
      {
        person: new Person(userSession.loadUserData().profile)
      },
      () => this.getUserInvites()
    );
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  async delete() {
    let _invites = await DuelInvitation.fetchList();
    _invites.forEach(invite => {
      invite.destroy();
    });
  }

  render() {
    const { userSession } = this.props;

    return (
      <div className="invite-feed-button-wrapper">
        <button
          className={this.state.invitesButtonStyle}
          onClick={() => {
            this.setState({ modalIsOpen: true });
          }}
        >
          <i className="fas fa-2x fa-bell"></i>
        </button>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal.bind(this)}
          style={customStyles}
          contentLabel="Invites"
        >
          <div className="home-sketch-wrapper">
            {this.state.isLoading && (
              <span className="loading-center">
                <CircularProgress style={{ color: "#3700FF" }} />
                <span>Loading...</span>
              </span>
            )}
            {this.state.userInvites.length > 0 ? (
              <div className="invites-list">
                {this.state.userInvites.map(invite => (
                  <DuelInviteListItem 
                  key={invite.attrs._id} 
                  className="invite" 
                  invitation={invite}
                  userSession={userSession} />
                ))}
              </div>
            ) : (
              <div>No Invites Yet</div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
