import React, { Component } from "react";
import Sketch from "../../models/Sketch";
import SnackBarWrapper from "../Shared/SnackBarWrapper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Reaction from "../../models/Reaction";
import DuelSketch from "../../models/DuelSketch";
import DuelMatch from "../../models/DuelMatch";
import SingleSketch from "../SingleSketch/SingleSketch";
import DuelListItem from "./DuelListItem";
import DuelList from "./DuelList";
import ReactLoading from "react-loading";

export default class DuelFeed extends Component {
  limit = "500";
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sketches: [],
      input: "",
      open: false,
      mostRecent: [],
      mostReacted: [],
      matches: []
    };
  }

  componentDidMount() {
    this.fetchDuelSketches();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reaction !== this.props.reaction) {
      this.changeReaction();
    }
  }

  fetchDuelSketches() {
    this.setState({ isLoading: true });
    this.fetchDuelMatches()
  }

  /**
   * first fetch all matches
   */
  // async fetchDuelMatches(){
  //   this.setState({
  //     matches : []
  //   })
  //   let _matches = await DuelMatch.fetchList({ sort: '-createdAt', limit: this.limit }, {decrypt: false });
  //   console.log(_matches)
  //   let _legitMatches = []
  //   await _matches.forEach(async match => {
  //     // console.log(match)
  //     let _sketches = await DuelSketch.fetchList({ invitationId: match.attrs.invitationId });
  //     //basically this is a workaround to check if this match has any posted sketch
  //     //TODO:optimize
  //     if (_sketches.length > 0) {
  //       _legitMatches.push(match)
  //     }
  //   }).finally(()=>{  this.setState({ matches: _legitMatches },()=>console.log(_legitMatches)); });
  //   // set the state hoe

  // }

  async fetchDuelMatches() {
    this.setState({
      matches: []
    });
    let _matches = await DuelMatch.fetchList(
      { sort: "-createdAt", limit: this.limit },
      { decrypt: false }
    );
    this.fetchSketches(_matches); // set the state hoe
  }

  async fetchSketches(matches) {
    let _legitMatches = [];
    for (const match of matches) {
      let _sketches = await DuelSketch.fetchList(
        { invitationId: match.attrs.invitationId },
        { decrypt: false }
      );
      if (_sketches.length > 0) {
        _legitMatches.push(match);
      }
    }
    this.setState({ matches: _legitMatches }, () =>       
    this.setState({ isLoading: false })
    );
  }

  /**
   * sorry this function will never be used
   * @param {*} match
   */
  async setMatchObject(match) {
    let _undefinedSketch = { title: "No Sketch" };
    const matchId = match.attrs.invitationId; //this could change it lame
    let _sketches = await DuelSketch.fetchList(
      { invitationId: matchId },
      { decrypt: false }
    );
    let duelMatch = {
      match: match,
      sketch1: _sketches[0] !== undefined ? _sketches[0] : _undefinedSketch,
      sketch2: _sketches[1] !== undefined ? _sketches[1] : _undefinedSketch
    };
    return duelMatch;
  }

  render() {
    return (
      <div className="duel-home-sketch-wrapper">
        {this.state.isLoading ? (
          <div className="loading-center" style={{ width : "100%", display: "flex", textAlign: "center", justifyContent : "center" }}>
            <ReactLoading
              type="bubbles"
              color="#3700FF"
              height={"10%"}
              width={"10%"}
            />
            <h3> Loading...</h3>
          </div>
        ) : this.state.matches.length !== 0 ? (
          <div style={{display: "flex", flexFlow:"row wrap", justifyContent : "center"}}>
            {this.state.matches.map((match, index) => {
              return (
                <DuelList
                  key={index}
                  match={match}
                  userSession={this.props.userSession}
                  fetchDuelMatches={this.fetchDuelMatches.bind(this)}
                />
              );
            })}
          </div>
        ) : (
          <div>
            {" "}
            <p>No Matches to display</p>
          </div>
        )}
        {/* <SnackBarWrapper
          message="There is an error. Please try again later"
          open={this.state.open}
          variant="Error"
          onClose={this.handleCloseSnackBar.bind(this)} /> */}
      </div>
    );
  }
}
