import React, { Component } from "react";
import TimeStamp from "../Shared/timestamp.js";
import { Person, lookupProfile } from "blockstack";
import moment from "moment";
import DuelInvitationRespond from "../../models/DuelInvitationRespond.js";
import { withRouter } from "react-router";
import DuelMatch from "../../models/DuelMatch";
import ProfilePic from "../Profile/ProfilePic.js";


const selectedClass = "btn-reaction-selected";

const avatarFallbackImage =
  "/images/DcassoDuel.png";

export default class DuelInviteListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: {
        name() {
          return "Anonymous";
        },
        avatarUrl() {
          return avatarFallbackImage;
        }
      },
      username: "",
      createdAt: ""
    };
  }

  convertDate(createdAt) {
    return TimeStamp.convertDate(createdAt).toLowerCase();
  }
  getProfile() {
    let username = this.props.invitation.attrs.sender;

    lookupProfile(username).then(profile => {
      this.setState({
        person: new Person(profile),
        username: username
      });
    });
  }
  checkValidInvitation(createdAt) {
    if (moment().diff(moment(createdAt), "minutes") <= 100) {
      return false;
    } 
      return true;
  }

  acceptInvitation() {
    const { invitation, userSession } = this.props
    console.log(invitation, userSession)
    DuelInvitationRespond.acceptInvitation(userSession, invitation)
    .finally(()=>{
      // this.props.history.push(
      //   `/invitation/${invitation.attrs._id}`
      // );
    })
  }
  denyInvitation() {
    const { invitation, userSession } = this.props
    DuelInvitationRespond.denyInvitation(userSession, invitation)
  }
  componentDidMount() {
    this.getProfile();
    this.convertDate();
  }

  acceptInvitation() {
    const { invite, userSession } = this.props

    DuelInvitationRespond.acceptInvitation(userSession , invite)
    .finally(() => {
      DuelMatch.createMatch(userSession, invite)
    })
  }

  denyInvitation() {
    const { invite, userSession } = this.props

    DuelInvitationRespond.denyInvitation(userSession , invite)
  }

  render() {
    const { invitation } = this.props;

    return (
      <React.Fragment>
        <div className="sketch-top-wrapper">
          <div className="photos-wrapper">
            <ProfilePic person = {this.state.person}/>
            {/* <div
              className="photos"
              style={{
                backgroundImage: `url(${
                  this.state.person.avatarUrl()
                    ? this.state.person.avatarUrl()
                    : avatarFallbackImage
                })`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }}
            /> */}
          </div>
          <div className="top-user-data">
            <div className="">
              <div className="top-username">{invitation.attrs.sender}</div>
            </div>
            <br />
            <div className="">
              <div className="top-createdAt">{this.convertDate(invitation.attrs.createdAt)}</div>
            </div>
          </div>
          <div className="invite-buttons-wrapper">
          {this.checkValidInvitation(invitation.attrs.createdAt) ?
            <button className="invite-accept btn-light text-danger" disabled>Expired</button>
          :
          <React.Fragment>
            <button className="invite-accept btn-primary" onClick={this.acceptInvitation.bind(this)}>Accept</button>
            <button className="invite-decline" onClick={this.denyInvitation.bind(this)}>Decline</button>
            </React.Fragment>
          }
          </div>
        </div>
      </React.Fragment>
    );
  }
}
