import React, { Component } from "react";
import { withRouter } from "react-router";
import DuelNotification from "../../models/DuelNotification";

class DuelNoti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message : ''
    }
    const notiCallback = duelNoti => {

      if (duelNoti.attrs.invitationId === this.props.match.params.invitationId) {
        if (duelNoti.attrs.type === 'discard'){
          this.discardNoti(duelNoti.attrs.username);
        } else if (duelNoti.attrs.type === 'done'){
          this.doneNoti(duelNoti.attrs.username);
        }
      }
    };
    DuelNotification.addStreamListener(notiCallback.bind(this));
  }
  discardNoti(username){
    this.setState({
      message : `Player ${username} has surrendered and left the match`
    })
  }

  /**
   * TODO: create object to when user click done
   * @param {*} username 
   */
  doneNoti(username){
    const { userSession } = this.props;
    if (userSession.loadUserData().username != username){
      this.setState({
        message : `Player ${username} has finished their masterpiece and waiting for you (no rush tho)`
      })
    } else {
      this.setState({
        message : `Please patiently wait for your opponent to finish`
      })
    }

  }
  render(){
    return(
    <p>{this.state.message}</p>
    )
  }
}
export default withRouter(DuelNoti);
