import React, { Component } from "react";
import DuelListItem from "./DuelListItem";
import DuelSketch from "../../models/DuelSketch";

export default class DuelList extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      //dummies
      sketch1: {
        attrs: {
          invitationId: "",
          username: "",
          mode: "",
          binary: "",
          prompt: "",
          createdAt: ""
        }
      },
      sketch2: {
        attrs: {
          invitationId: "",
          username: "",
          mode: "",
          binary: "",
          prompt: "",
          createdAt: ""
        }
      }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { match } = this.props;
    this.getSketchesByInvitationId(match);
  }
  componentDidUpdate(){
    
  }
  componentWillUnmount(){
    this._isMounted = false;
  }

  async getSketchesByInvitationId(match) {
    const matchId = match.attrs.invitationId; //this could change it lame
    let _sketches = await DuelSketch.fetchList({ invitationId: matchId }, {decrypt : false});
    if (_sketches.length > 0) {
      this.setState({
        sketch1: _sketches[0],
        sketch2: _sketches.length > 1 ? _sketches[1] : null //how to handle sketch that does not exist?
      });
    }
  }

  //TODO: if user 1 exits first before user2 finishes
  getSketchStatus(){

  }

  async deleteSketch(sketch){
    try {
      await sketch.destroy()
      this.props.fetchDuelMatches()
    } catch(e){
      console.log("fail to delete")
    }
  }
  render() {
    return (
      this._isMounted && <DuelListItem
        key={this.state.sketch1.attrs.invitationId}
        // match={match.match}
        sketch1={this.state.sketch1}
        sketch2={this.state.sketch2}
        deleteSketch={this.deleteSketch.bind(this)}
        userSession={this.props.userSession}
      />
    );
  }
}
