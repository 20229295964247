import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  Person, lookupProfile,
} from 'blockstack';
import SortInput from './Home/SortInput';
import DuelInviteFeedButton from './DuelHome/DuelInviteFeedButton';
import Intro from './DuelHome/Intro';

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: ''
    }
  }


  render() {
    const { handleSignIn, handleSignOut, userSession } = this.props;
    const { person } = this.state;
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-style">
        <Link to="/" className="navbar-brand navbar-brand-margin">
          <img src="/images/DcassoDuel-word-white-border.png" className="logo-frame" width="100" height="100" alt="Dcasso logo" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <SortInput setInput={this.props.setInput} /> */}
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ml-auto">
          <Intro />

            {userSession.isUserSignedIn() &&
              <React.Fragment>
                {/* <Link to={"/profile"} className="nav-item btn btn-primary btn-lg" id="profile-feed">Profile</Link> */}
                
                {/* <DuelInviteFeedButton userSession={this.props.userSession}  /> */}
                <button
                  className="nav-item btn btn-primary btn-lg"
                  id="signout-button"
                  onClick={handleSignOut.bind(this)}
                >
                  Logout
            </button>
              </React.Fragment>}
            {!userSession.isUserSignedIn() &&
              <Link to="/signin">
                <button
                className="nav-item btn btn-primary btn-lg"
                id="signout-button"
                onClick={handleSignIn.bind(this)}
              >
                Log In
          </button></Link>}
          </div>
        </div>
      </nav>
    );
  }

  componentDidMount() {
    const { userSession } = this.props;
    if (userSession.isUserSignedIn()) {
      this.setState({
        person: new Person(userSession.loadUserData().profile),
      });
    }
  }
}