import React, { Component } from 'react';
import ReactionBar from './ReactionBar';
import Reaction from '../../models/Reaction';
import { User } from 'radiks/lib';
import Sketch from '../../models/Sketch';
import DeleteSketchButton from './DeleteSketchButton';
import SketchTop from './SketchTop';
import SignInNoticeModal from '../Shared/SignInNoticeModal';

const selectedClass = 'btn-reaction-selected';

export default class SingleSketch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // sketch: this.props.sketch,
      modalIsOpen : false,
      loveCount: 0,
      laughCount: 0,
      poopCount: 0,
      reactionCount: 0,
      isLove: false,
      isLaugh: false,
      isPoop: false,
      avatar: null,
      selected: '',

    }
  }

  componentDidMount() {
    const {userSession} = this.props
    this.getAvatar();
    this.getSketchReations()
    if (userSession.isUserSignedIn()) {
      this.getSelfReaction();
    }
  }
 

  async getAvatar() {
    let _user = await User.findById(this.props.sketch.attrs.username, { decrypt: false });
    if(typeof _user !== 'undefined' ){
      this.setState({ avatar: _user.avatarURL })
    }
  }

  async getSketchReations(){
    let _reactions = await Reaction.fetchList({ sketchId : this.props.sketch._id} , { decrypt: false })
    let _loveCount = 0, _laughCount = 0, _poopCount = 0;

    _reactions.forEach(reaction => {
      if (reaction.attrs.type === 'love') {
        _loveCount += 1;
      } else if (reaction.attrs.type === 'laugh') {
        _laughCount += 1;
      } else if (reaction.attrs.type === 'poop') {
        _poopCount += 1;
      }
    })
    this.setState({
      loveCount : _loveCount,
      laughCount : _laughCount,
      poopCount : _poopCount
    })
  }

  // async getSketchReations(){
  //   // let _loveCount = 0, _laughCount = 0, _poopCount = 0;
  //   let _loveReactions = await Reaction.fetchList({ sketchId : this.props.sketch._id}, {type: 'love' }, {decrypt: false});
  //   let _laughReactions = await Reaction.fetchList({ sketchId : this.props.sketch._id}, {type: 'laugh'}, {decrypt: false});
  //   let _poopReactions = await Reaction.fetchList({ sketchId : this.props.sketch._id}, {type: 'poop' }, {decrypt: false});
  //   let _loveCount = _loveReactions.length, _laughCount = _laughReactions, _poopCount = _poopReactions ;
  //   let _reactionCount = _loveCount + _laughCount + _poopCount;
  //   this.setState({
  //     loveCount : _loveCount,
  //     laughCount : _laughCount,
  //     poopCount : _poopCount,
  //     reactionCount: _reactionCount
  //   })
  // }
  async getSelfReaction() {
    let _reaction = await Reaction.findOne({
      sketchId: this.props.sketch._id,
      userId: User.currentUser()._id,

    });
    if (typeof _reaction !== 'undefined') {
      // this.setState({
      //   loveCount: this.props.sketch.attrs.loveCount,
      //   laughCount: this.props.sketch.attrs.laughCount,
      //   poopCount: this.props.sketch.attrs.poopCount

      // })
      if (_reaction.attrs.type === 'love') {
        this.setState({ selected: _reaction.attrs.type, isLove: true });
      }
      else if (_reaction.attrs.type === 'laugh') {
        this.setState({ selected: _reaction.attrs.type, isLaugh: true });
      }
      else if (_reaction.attrs.type === 'poop') {
        this.setState({ selected: _reaction.attrs.type, isPoop: true });
      }
    }
  }

  async saveReaction(reactionType) {
    // console.log("reached saveReaction()")
    let _reaction = new Reaction({
      sketchId: this.props.sketch._id,
      userId: User.currentUser()._id,
      type: `${reactionType}`,
    });
    console.log(User.currentUser()._id)
    await _reaction.save();
    this.updateSketchReactions();
  }

  //this shouldn't be called cuz it's not user's own sketch...?
  async updateSketchReactions() {
    let _sketch = await Sketch.findById(this.props.sketch._id);
    _sketch.update({
      reactionCount: this.state.reactionCount,
      loveCount: this.state.loveCount,
      laughCount: this.state.laughCount,
      poopCount: this.state.poopCount,
    });
    await _sketch.save();
  }


  async deleteReaction() {
    let _reaction = await Reaction.findOne({
      sketchId: this.props.sketch._id,
      userId: User.currentUser()._id,
    });
    if (_reaction !== undefined) {
      await _reaction.destroy();
    }

    this.updateSketchReactions();
  }

  resetReaction() {
    if (this.state.isPoop) {
      this.setState({ poopCount: this.state.poopCount - 1, isPoop: false, reactionCount: this.state.reactionCount - 1, selected: '' }, () => { this.deleteReaction() });
    }
    else if (this.state.isLaugh) {
      this.setState({ laughCount: this.state.laughCount - 1, isLaugh: false, reactionCount: this.state.reactionCount - 1, selected: '' }, () => { this.deleteReaction() });
    }
    else if (this.state.isLove) {
      this.setState({ loveCount: this.state.loveCount - 1, isLove: false, reactionCount: this.state.reactionCount - 1, selected: '' }, () => { this.deleteReaction() });
    }
  }
  handleReact(reactionType){
    const { userSession } = this.props
    if (userSession.isUserSignedIn()) {
      if (reactionType === 'love') {
        this.handleLoveReact()
      } else if (reactionType === 'laugh') {
        this.handleLaughReact()
      } else if (reactionType === 'poop') {
        this.handlePoopReact()
      }
    } else {
      this.setState({
        modalIsOpen : true
      })
    } 
  }
  handleLoveReact() {
    let _isLove = this.state.isLove;
    this.resetReaction();
    if (!_isLove) {
      this.setState({ 
        isLove: true, 
        isLaugh: false, 
        isPoop: false, 
        loveCount: this.state.loveCount + 1, 
        reactionCount: this.state.reactionCount + 1, 
        selected: 'love' 
      }, () => { this.saveReaction('love') });
    }
  }

  handleLaughReact() {
    let _isLaugh = this.state.isLaugh;
    this.resetReaction();
    if (!_isLaugh) {
      this.setState({ isLaugh: true, isLove: false, isPoop: false, laughCount: this.state.laughCount + 1, reactionCount: this.state.reactionCount + 1, selected: 'laugh' }, () => { this.saveReaction('laugh') });
    }
  }

  handlePoopReact() {
    let _isPoop = this.state.isPoop;
    this.resetReaction();
    if (!_isPoop) {
      this.setState({ isPoop: true, isLove: false, isLaugh: false, poopCount: this.state.poopCount + 1, reactionCount: this.state.reactionCount + 1, selected: 'poop' }, () => { this.saveReaction('poop') });
    }
  }

  /**
   * helper to check if user is viewing their own sketch
   */
  isLocal() {
    const { userSession } = this.props
    if (userSession.isUserSignedIn()) {
      return userSession.loadUserData().username === this.props.sketch.attrs.username
    }
    return false;
  }
  openModal(){
    this.setState({modalIsOpen: true});	
  }
  closeModal(){
    this.setState({modalIsOpen: false});	
  }
  render() {
    const { sketch } = this.props;
    const _promptLength = sketch.attrs.prompt ? sketch.attrs.prompt.length : 0;

    return (
      <React.Fragment>
      <div className="home-sketch-card container" >
        {this.isLocal() &&
          <DeleteSketchButton
            deleteSketch={this.props.deleteSketch}
            sketch={sketch} />}
        <SketchTop sketch={sketch} />
        <div className="single-sketch-info">
          {_promptLength < 9 ?
            <div className="col sketch-word-text-small">
              {sketch.attrs.prompt}
            </div>
            :
            <div className="col sketch-word-text-big">
              {sketch.attrs.prompt}
            </div>
          }
          <div className="sketch-mode">{sketch.attrs.mode}</div>
        </div>
        <img src={sketch.attrs.binary} style={{ background: 'white', width: '100%' }} />
        <ReactionBar
          loveCount={this.state.loveCount}
          laughCount={this.state.laughCount}
          poopCount={this.state.poopCount}
          selected={this.state.selected}
          onLove={() => { this.handleLoveReact() }}
          onLaugh={() => { this.handleLaughReact() }}
          onPoop={() => { this.handlePoopReact() }}
          onReact={this.handleReact.bind(this)}
        />
      </div>
      <SignInNoticeModal
      modalIsOpen={this.state.modalIsOpen}
      closeModal = {this.closeModal.bind(this)}	      
      />
      </React.Fragment>
    )
  }
}