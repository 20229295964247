import React, { Component } from 'react';

const buttonStyles = ' reaction-button btn-reaction btn-reaction-clicked';
const leftButton = ' reaction-button-left-side';
const centerButton = ' reaction-button-center';
const rightButton = ' reaction-button-right-side';
const selectedClass = ' btn-reaction-selected';

export default class ColorButton extends Component {


  render() {
    return (
      <div className="color-button-wrapper">
        <input className="color-radio-button" type="radio" value={this.props.color} checked={this.props.isSelected}>
        </input>
        <div className="color-div" style={{ backgroundColor: this.props.color }}>
          bob
                    </div>
      </div>
    )
  }
}