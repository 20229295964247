import React, { Component } from "react";
import DuelOnline from "../../models/DuelOnline";
import moment from "moment";
import IdleTimer from "react-idle-timer";
import Toggle from "react-toggle";
import "react-toggle/style.css";

export default class OnlineWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onlinePeople: [],
      active: false,
      duelOnline: {
        attrs: {
          online: true,
          defaultOnline: true
        }
      }
    };
    const onlineCallback = duelOnline => {
      // console.log("online callback",duelOnline.attrs.username,duelOnline.attrs.online)
      let index = this.state.onlinePeople.indexOf(duelOnline.attrs.username);
      let people = this.state.onlinePeople;

      if (duelOnline.attrs.online && index == -1) {
        people.push(duelOnline.attrs.username);
        this.setState({
          onlinePeople: people
        });
      }
      if (!duelOnline.attrs.online && index > -1) {
        people.splice(index, 1);
        this.setState({
          onlinePeople: people
        });
      }
    };
    DuelOnline.addStreamListener(onlineCallback.bind(this));
  }


  componentDidMount() {
    const { userSession } = this.props;

    this.fetchOnlinePeople();
    if (userSession.isUserSignedIn()) {
      this.fetchOnlineStatus();
    }
    setInterval(() => {
      console.log("fetch online people")
    this.fetchOnlinePeople();
      
    }, 300000);
  }

  /**
   * fetch self online object
   */
  async fetchOnlineStatus() {
    const { userSession } = this.props;
    const onlines = await DuelOnline.fetchOwnList();
    //if user first time logs in -> create new active object
    if (onlines.length === 0) {
      const online = new DuelOnline({
        username: userSession.loadUserData().username
      });
      await online.save();
      await this.setState({
        duelOnline: online,
        active: true
      });
      //if NOT first time logs in -> update current active object, only if user default online is true
    } else {
      if (onlines[0].attrs.defaultOnline) {
        onlines[0].update({
          online: true
        });
        await onlines[0].save();
      }
      await this.setState({
        duelOnline: onlines[0],
        active: onlines[0].attrs.defaultOnline
      });
    }
    // for (let i = 0 ; i < onlines.length; i++) {
    //   await onlines[i].destroy()
    // }
  }

  async fetchOnlinePeople() {
    const time = moment()
      .subtract(5, "minutes")
      .toDate()
      .getTime();
    const onlinePeople = await DuelOnline.fetchList(
      { updatedAt: { $gte: time } ,  online : true},
      { decrypt: false }
    );
    const people = [];
    for (let online of onlinePeople) {
      people.push(online.attrs.username);
    }
    await this.setState({
      onlinePeople: people
    });
  }


  async onAction(e) {
    const time = moment()
    .subtract(10, "minutes")
    .toDate()
    .getTime();
    //if last updated was more 10 minutes ago
    if (this.state.active && (!this.state.duelOnline.attrs.online || this.state.duelOnline.attrs.updatedAt <= time )){
      try {
      this.state.duelOnline.update({
        online: true
      });
      await this.state.duelOnline.save();
      await this.setState({
        duelOnline: this.state.duelOnline
      });
      }catch(e){
        console.error(e)
      }
    }
  }

  async onActive(e) {
    // console.log('time remaining', moment(this.idleTimer.getRemainingTime()).format())
    if (this.state.active ) {
      this.state.duelOnline.update({
        online: true
      });
      await this.setState({
        duelOnline: this.state.duelOnline
      });
      await this.state.duelOnline.save();
    }
  }

  async onIdle(e) {
    console.log("user is idle", e);
    const time = moment()
    .subtract(10, "minutes")
    .toDate()
    .getTime();
    // console.log('last active', moment(this.idleTimer.getLastActiveTime()).format())
    if (this.state.active) {
      try {
      this.state.duelOnline.update({
        online: false
      });
      await this.state.duelOnline.save();
      await this.setState({
        duelOnline: this.state.duelOnline
      });
      }catch(e){
        console.error(e)
      }
    }
  }
  handleActiveChange(e) {
    this.setState(
      {
        active: e.target.checked
      },
      () => this.setDefaultOnline()
    );
  }

  async setDefaultOnline() {
    // console.log(`current active state ${this.state.active}, default online ${this.state.duelOnline.attrs.defaultOnline}`)
    if (this.state.active !== this.state.duelOnline.attrs.defaultOnline) {
      try {
        this.state.duelOnline.update({
          defaultOnline: this.state.active,
          online: this.state.active
        });
        await this.state.duelOnline.save();
        await this.setState({
          duelOnline: this.state.duelOnline
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    const {userSession} = this.props;
    return (
      <div className="feed-online-wrapper">
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive.bind(this)}
          onIdle={this.onIdle.bind(this)}
          // onAction={this.onAction.bind(this)}
          debounce={0}
          timeout={1000 * 300}
        />
        <h4>Active Artists</h4>
        {userSession.isUserSignedIn() &&
        <div>
        <label htmlFor="active-status" className="active-label"  data-toggle="tooltip" title="Set your Active status for the general public">
        You're currently {this.state.active ? "online" : "offline"}
        </label>
        <Toggle
          id="active-status"
          checked={this.state.active}
          onChange={this.handleActiveChange.bind(this)}
        /> </div>}

        {this.state.onlinePeople.length > 0 &&
          this.state.onlinePeople.map((online, index) => {
            return (
              userSession.isUserSignedIn() && online !== userSession.loadUserData().username && 

              <div className="online-list" key={index}>
                <div className="online-name">
                <i className="fas fa-circle"></i> {online}
                </div>
                <div>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" , padding: '7px 15px'}}
                  onClick={this.props.inviteActive.bind(this, online)}
                >
                  Invite
                </button>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}
