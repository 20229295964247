import { Model } from 'radiks';

export default class DuelNotification extends Model {
    static className = 'DuelNotification';
    static schema = {
      invitationId: { type: String, required : true, decrypted: true },
      username : { type: String, required : true, decrypted: true },
      // message: { type: String, required : true, decrypted: true }, //noti message 
      type : { type: String, required : true, decrypted: true }, //type "discard"
    };
   
}

