import { Model } from 'radiks';

export default class DuelVote extends Model {
    static className = 'DuelVote';
    static schema = {
        invitationId: { type: String, required : true, decrypted: true },
        userId : { type: String, required : true, decrypted: true },
        sketchId: { type: String, required : true, decrypted: true }, 
    };
    
}

