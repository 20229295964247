import React, { Component } from "react";
import Modal from "react-modal";
import DuelRematchRespond from "../../models/DuelRematchRespond";
import { withRouter } from "react-router";

Modal.setAppElement("body");
const customStyles = {
  content: {
    width: "600px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    zIndex : "16"
  }
};
class RematchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
  }
  async acceptRematch(){
    const { userSession } = this.props;

    try {
      const respond = new DuelRematchRespond({
        invitationId : this.props.match.params.invitationId,
        username : userSession.loadUserData().username,
        accept : true
      })
      await respond.save()
    } catch(e){
      alert("Unable to accept for a rematch atm. Please try again later")
      console.log(e)
    }
    this.props.closeModal()
  }
  async denyRematch(){
    const { userSession } = this.props;

    try {
    const respond = new DuelRematchRespond({
      invitationId : this.props.match.params.invitationId,
      username : userSession.loadUserData().username,
      accept : false
    })
    await respond.save()
  } catch(e){
    alert("Unable to deny for a rematch atm. Please try again later")
    console.log(e)
  }
    this.props.closeModal();
  }
  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        // onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Log In"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="modal-title">Rematch Request</h1>
              <p>Your opponent isn't satisfied with their masterpiece so they requested a rematch</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
                <button type="button" className="btn btn-primary"
                onClick={this.acceptRematch.bind(this)}>
                  Accept
                </button>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={this.denyRematch.bind(this)}
              >
                Deny
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default withRouter(RematchModal);
