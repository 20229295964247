import { Model } from 'radiks';

export default class DuelStatus extends Model {
    static className = 'DuelStatus';
    static schema = {
      invitationId: { type: String, required : true, decrypted: true },
      username : { type: String, required : true, decrypted: true },
      status : { type: String, required : true, decrypted: true }, //type "ready","done","ditched","published"
    };
    static defaults = {
      status : 'NOT READY'
    };

    static async sendExitNoti(userSession , invitationId) {
      console.log("send exit noti")
      try {
        const noti = new DuelStatus({
          invitationId: invitationId,
          username: userSession.loadUserData().username,
          status: "DITCHED"
        });
        await noti.save();
      } catch (e) {
        console.log(e);
      }
    }
}

