import { Model } from "radiks";

export default class DuelSketch extends Model {
  static className = "DuelSketch";
  static schema = {
    invitationId: { type: String, required: true, decrypted: true },
    username: { type: String, required: true, decrypted: true },
    binary: { type: String, required: true, decrypted: true },
    prompt: { type: String, required: true, decrypted: true },
    mode: { type: String, required: true, decrypted: true }, //easy, medium, hard
    vote: { type: Number, decrypted: true },
  };
  static defaults = {};
}
